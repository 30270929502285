import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  GET_SYNC_ERROR_LOGS_SAGA
} from 'constants/ActionTypes';
import {
  errorMessage,
  updateSyncErrorLogsInStore
} from 'actions';
import { microserviceAPI } from 'util/MicroserviceAPI';
import { LFAPI } from 'util/LFAPI'

function* getPaymentSyncErrorLogs(payload) {
  let queryString = `source=supplier`
  if (payload.integrationType) {
    queryString = queryString + `&integration=${payload.integrationType}`
  }
  try {
    const endPoint = `syncLogs/list?${queryString}`;
    const response = yield microserviceAPI(endPoint, { ref: "CHACHING" });
    //let columns = [];
    //let columnRes = response.columns;
    // Object.keys(columnRes).map(each => {
    //   let currentColumn = columnRes[each];
    //   columns.push({ headerName: currentColumn.label, field: each, hide: !!currentColumn.hide })
    // })
    return ({ ...response });
  } catch (err) {
    yield put(errorMessage('There was an error retrieving payment error logs. Please try again.', 'Error'));
    return ({ columns: [], rows: [] });
  }
}

function* getInvoiceSyncErrorLogs(payload) {
  let queryString = '';
  if (payload.integrationType) {
    queryString = `integration_type=${payload.integrationType}&`
  }
  if (payload.entityType) {
    queryString += `entity_type=${payload.entityType}`
  }
  try {
    const endPoint = `/integration-error-logs/list?${queryString}`;
    const response = yield LFAPI.get(endPoint);
    const erroLogs = response.data.data;
    return (erroLogs);
  } catch (err) {
    yield put(errorMessage('There was an error retrieving invoice error logs. Please try again.', 'Error'));
    return ({ columns: [], rows: [] });
  }
}

function* getSyncErrorLogs(action) {
  let { payload } = action;
  try {
    let paymentRes = { columns: [], rows: [] }, invoiceRes = { columns: [], rows: [] }, allRes;
    if (payload.entityType == 'all') {
      invoiceRes = yield getInvoiceSyncErrorLogs(action.payload);
      paymentRes = yield getPaymentSyncErrorLogs(action.payload);
      allRes = { columns: [...invoiceRes.columns, ...paymentRes.columns], rows: [...invoiceRes.rows, ...paymentRes.rows] };
      allRes.columns = allRes.columns.reduce((unique, each) => {
        if (!unique.some(obj => obj.field === each.field)) {
          unique.push(each);
        }
        return unique;
      }, []);
      allRes.rows = allRes.rows.sort((a, b) => {
        var dateA = new Date(a.job_started_at).getTime();
        var dateB = new Date(b.job_started_at).getTime();
        return dateA < dateB ? 1 : -1;
      });

      allRes.rows = allRes.rows.map((item, index) => {
        return {
          s_no: index + 1,
          ...item
        }
      });
    }
    else if (payload.entityType == 'payment')
      allRes = yield getPaymentSyncErrorLogs(action.payload);
    else if (payload.entityType == 'invoice')
      allRes = yield getInvoiceSyncErrorLogs(action.payload);
    yield put(updateSyncErrorLogsInStore(allRes));
  } catch (err) {
    yield put(updateSyncErrorLogsInStore({ columns: [], rows: [] }));
    yield put(errorMessage('There was an error retrieving error logs. Please try again.', 'Error'));
  }
}


export function* watchSyncErrorLogsSaga() {
  yield takeEvery(GET_SYNC_ERROR_LOGS_SAGA, getSyncErrorLogs);
}

export default function* rootSaga() {
  yield all([fork(watchSyncErrorLogsSaga)]);
}