import React, { useState, useEffect } from 'react';
import ComponentDialog from 'components/ComponentDialog';
import Checkbox from 'material-ui/Checkbox';
import { microserviceAPI } from 'util/MicroserviceAPI';
import { NotificationManager } from 'react-notifications';

function ReTOSBox(props) {
	const [showBox, setShow] = useState(false)
	const [accept, setAccept] = useState(false)
	const [paymentProfileId, setProfileId] = useState()

	useEffect(() => {
		(async () => {
			const userDetails = JSON.parse(localStorage.getItem("userDetails"));
			const isBoxShown = userDetails?.isTOSShown;
			if (userDetails && !isBoxShown) {
				const supplierUUID = userDetails?.supplierUuid;
				const profileDetails = supplierUUID ? await microserviceAPI(`payment_profiles?supplier=${supplierUUID}`, { ref: "CHACHING" }) : null;

				const paymentProfileId = profileDetails?.data?.[0]?.id;
				setProfileId(paymentProfileId)

				const response = paymentProfileId ? await microserviceAPI(`stripe/reTOS-check/${paymentProfileId}`, { ref: "CHACHING" }) : null;
				const showTOSBox = response?.reTOSNeeded;
				setShow(showTOSBox);
				userDetails.isTOSShown = true;
				localStorage.setItem('userDetails', JSON.stringify(userDetails));
			}
		})();
	}, [])



	const handleAccept = () => {
		if (paymentProfileId) {
			fetch('https://api.ipify.org/?format=json')
				.then(response => response.json())
				.then(async ({ ip }) => {
					setShow(false)
					let profileStatus = await microserviceAPI(`stripe/reTOS-update/${paymentProfileId}`, {
						ref: 'CHACHING', method: 'PUT', body: {
							ip,
							timestamp: Math.floor(Date.now() / 1000),
						}
					});

					if (profileStatus.operation == 'success') {
						NotificationManager.success('You have successfully accepted updates to LFPay Services.')
					} else {
						NotificationManager.error('Acceptance of the LFPay Services was unsuccessful.')
					}
				})
				.catch(e => {
					NotificationManager.error('Acceptance of the LFPay Services was unsuccessful.')
				})
		} else {
			NotificationManager.error('Acceptance of the LFPay Services was unsuccessful.')
		}

	}

	return (

		<ComponentDialog
			show={showBox}
			acceptText="Accept"
			cancelText="Decline"
			disableAcceptText={!accept}
			disableCloseIcon={true}
			disableBackdrop={true}
			handleCancel={() => { setShow(false) }}
			handleAccept={handleAccept}
			title={'LFPay Services Update'}
			body={
				<>
					<div style={{ maxWidth: 350, padding: '10px 0px', lineHeight:1.8 }}>
						To continue receiving electronic payments, you agree to the <a style={{ color: '#689cd9' }} rel="noopener noreferrer" href="https://stripe.com/legal/connect-account" target="_blank">Services Agreement</a> of our payment processing platform.
					</div>
					<div>
						<Checkbox
							size='small'
							style={{ height: 25, width: 25 }}
							checked={accept}
							onChange={() => {
								setAccept(!accept)
							}} /> I agree and accept the Services Agreement
					</div>
				</>
			}
		/>
	);
}


export default ReTOSBox;

