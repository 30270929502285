import { all, fork, put, takeEvery, delay } from 'redux-saga/effects';
import {
    GET_ACCOUNT_RECEIVIBALE_SAGA,
    GET_SUPPLIER_ACCOUNTS_SAGA,
    GET_QBO_ALL_INVOICES_SAGA,
    GET_QBO_CUSTOMERS_SAGA,
    GET_QBO_STATUS_SAGA,
    DELETE_QBO_TOKEN_SAGA,
    UPSERT_QBO_CUSTOMER_SAGA,
    UPSERT_QBO_INCOME_ACCOUNT_SAGA,
    UPSERT_QBO_CLEARING_ACCOUNT_SAGA,
    UPSERT_QBO_ACCOUNT_RECEIVABLE_SAGA,
    SYNC_INVOICES_TO_QBO,
    GET_QBO_TAX_SAGA,
    UPSERT_QBO_TAX_SAGA,
    START_INVOICES_SYNC,
    START_PAYMENT_SYNC
} from 'constants/ActionTypes';

import { microserviceAPI } from '../util/MicroserviceAPI'
import { LFAPI } from 'util/LFAPI';

import {
    getQBOStatus, getQBOCustomers, getQBOAccountReceivable, getQBOIncome, getQBOClear, getSyncInvoicestoQBOStatus,
    successMessage,
    errorMessage,
    getQBOAllInvoices,
    getQBOTaxList,
    setPaymentSyncStatus,
    setInvoiceSyncStatus
} from 'actions'

function* getStatus() {
    try {
        const res = yield microserviceAPI('/status?=', { ref: "QBO" });
        if (res.connected) {
            yield put(getQBOStatus(res))
            let userDetails = yield JSON.parse(localStorage.getItem('userDetails'))
            const syncInvoiceToggle = yield LFAPI.get(`/integrations/qbo/sync/invoiceToggle?supplierUuid=${userDetails['supplierUuid']}&entityName=qbo`)
            const resposne = yield microserviceAPI('/customers', { ref: "QBO" });
            const accontResponse = yield microserviceAPI('/accounts?=&type=ar', { ref: "QBO" });
            const incomeResponse = yield microserviceAPI('/accounts?=&type=income', { ref: "QBO" });
            const clearResponse = yield microserviceAPI('/accounts?=&type=receive_payment', { ref: "QBO" });
            yield put(getSyncInvoicestoQBOStatus(syncInvoiceToggle.data))
            yield put(getQBOAccountReceivable(accontResponse.data))
            yield put(getQBOIncome(incomeResponse.data))
            yield put(getQBOClear(clearResponse.data))
            yield put(getQBOCustomers(resposne.data))


        }
        else {
            yield put(getQBOStatus(res))
        }
    } catch (error) {
        console.log('Error ', error)
        yield put(errorMessage("Quickbooks service is down", "Error"))
    }
}

function* getCustomers() {
    try {
        const resposne = yield microserviceAPI('/customers', { ref: "QBO" });
        yield put(getQBOCustomers(resposne.data))
    } catch (error) {
        console.log('Error ', error)
        yield put(errorMessage(error.message, "Error"))
    }
}

function* getTaxList() {
    try {
        const response = yield microserviceAPI('/taxcode', { ref: "QBO" });
        yield put(getQBOTaxList(response))
    } catch (error) {
        console.log('Error ', error)
        yield put(errorMessage(error.message, "Error"))
    }
}

function* getQboAllInvoices() {
    try {
        const response = yield microserviceAPI('/invoices', { ref: "QBO" });
        yield put(getQBOAllInvoices(response.data))
    } catch (error) {
        console.log('Error ', error)
        yield put(errorMessage(error.message, "Error"))
    }
}

function* deleteQboToken() {
    try {
        const res = yield microserviceAPI('/auth/revoke?=', { method: 'POST', ref: "QBO" });
        if (res.status == 204)
            yield put(getQBOStatus({ connected: false }))
    } catch (error) {
        console.log('Error ', error)
        yield put(errorMessage("Couldn't disconnect Quickbooks", "Error"))
    }
}

function* upsertQboCustomer({ payload }) {
    try {
        const res = yield microserviceAPI('/customers', { method: 'PUT', ref: "QBO", body: payload });
        yield put(successMessage(`${payload.name || payload.supplierAccount.name} is synced to QuickBooks`, "Success"))
        const resposne = yield microserviceAPI('/customers', { ref: "QBO" });
        yield put(getQBOCustomers(resposne.data))
    }
    catch (err) {
        yield put(errorMessage(`Couldn't sync Customer ${payload.name || payload.default_name}, ${err.message}`, "Error"))
    }
}

function* upsertQboTax({ payload }) {
    try {
        const res = yield microserviceAPI('/taxregime', { method: 'POST', ref: "QBO", body: payload });
        yield put(successMessage(`${payload.taxRateName} is synced to QuickBooks`, "Success"))
        getTaxList()
    }
    catch (err) {
        yield put(errorMessage(`Couldn't sync Tax ${payload.taxRateName}, ${err.message}`, "Error"))
    }
}

function* upsertQboIncomeAccount({ payload }) {
    const res = yield microserviceAPI('/accounts', { method: 'PUT', ref: "QBO", body: payload })
    const incomeResponse = yield microserviceAPI('/accounts?=&type=income', { ref: "QBO" });
    yield put(getQBOIncome(incomeResponse.data))
}

function* upsertQboClearingAccount({ payload }) {
    const res = yield microserviceAPI('/accounts', { method: 'PUT', ref: "QBO", body: payload })
    const clearResponse = yield microserviceAPI('/accounts?=&type=receive_payment', { ref: "QBO" });
    yield put(getQBOClear(clearResponse.data))
}

function* SyncStartInvoices({ payload }) {
    yield put(setInvoiceSyncStatus(true))
    try {
        const syncInvoiceProcess = yield LFAPI.post(`/invoices/push_invoice_sync`);
        console.log("syncInvoiceProcess==>", syncInvoiceProcess)
        yield put(successMessage("Invoices sync started", "Success"))
        yield delay(15000);
        yield put(setInvoiceSyncStatus(false))
    } catch (err) {
        let error_message = err.response.data.message
        yield put(setInvoiceSyncStatus(false))
        yield put(errorMessage(error_message, "Error"))
    }

}
function* SyncStartPayment({ payload }) {

    yield put(setPaymentSyncStatus(true))
    try {
        let URI = `qbo/receivePayments/push?supplier_uuid=${JSON.parse(localStorage.getItem('userDetails')).supplierUuid}`
        const res = yield microserviceAPI(URI, { ref: "CHACHING" });
        yield put(successMessage(`Payment Sync Started`, "Success"))
        yield delay(15000);
        yield put(setPaymentSyncStatus(false))
    } catch (err) {
        console.log("err==>", err)
        let error_message = err.message
        yield put(setPaymentSyncStatus(false))
        yield put(errorMessage(error_message, "Error"))
    }

}

function* upsertQboAccountReceivable({ payload }) {
    const res = yield microserviceAPI('/accounts', { method: 'PUT', ref: "QBO", body: payload })
    const accontResponse = yield microserviceAPI('/accounts?=&type=ar', { ref: "QBO" });
    yield put(getQBOAccountReceivable(accontResponse.data))
}


function* syncInvoicesToQBO({ payload }) {
    try {
        const { entityName, supplierUuid, email, entityKey, entityValue } = payload
        const res = yield LFAPI.post('/integrations/qbo/sync/invoiceToggle', {
            entityName,
            supplierUuid,
            email,
            entityKey,
            entityValue
        })
        if (res)
            yield put(getSyncInvoicestoQBOStatus(res.data))
    } catch (err) {
        yield put(errorMessage('There was an error in updating the database', 'Error'));
    }
}

export function* watchGetStatus() {
    yield takeEvery(GET_QBO_STATUS_SAGA, getStatus)
}

export function* watchGetCustomers() {
    yield takeEvery(GET_QBO_CUSTOMERS_SAGA, getCustomers)
}

export function* watchGetQboAllInvoices() {
    yield takeEvery(GET_QBO_ALL_INVOICES_SAGA, getQboAllInvoices)
}

export function* watchDeleteQboToken() {
    yield takeEvery(DELETE_QBO_TOKEN_SAGA, deleteQboToken)
}

export function* watchUpsertQboCustomer() {
    yield takeEvery(UPSERT_QBO_CUSTOMER_SAGA, upsertQboCustomer)
}

export function* watchUpsertQboTax() {
    yield takeEvery(UPSERT_QBO_TAX_SAGA, upsertQboTax)
}

export function* watchUpsertQboIncomeAccount() {
    yield takeEvery(UPSERT_QBO_INCOME_ACCOUNT_SAGA, upsertQboIncomeAccount)
}

export function* watchUpsertQboClearingAccount() {
    yield takeEvery(UPSERT_QBO_CLEARING_ACCOUNT_SAGA, upsertQboClearingAccount)
}

export function* watchSyncStartInvoices() {
    yield takeEvery(START_INVOICES_SYNC, SyncStartInvoices)
}
export function* watchSyncStartPayment() {
    yield takeEvery(START_PAYMENT_SYNC, SyncStartPayment)
}

export function* watchUpsertQboAccountReceivable() {
    yield takeEvery(UPSERT_QBO_ACCOUNT_RECEIVABLE_SAGA, upsertQboAccountReceivable)
}

export function* watchSyncInvoiceToQBO() {
    yield takeEvery(SYNC_INVOICES_TO_QBO, syncInvoicesToQBO)
}

export function* watchGetTaxList() {
    yield takeEvery(GET_QBO_TAX_SAGA, getTaxList)
}

export default function* rootSaga() {
    yield all([
        // fork(watchGetSupplierAccounts),
        fork(watchGetStatus),
        fork(watchGetCustomers),
        fork(watchGetQboAllInvoices),
        fork(watchDeleteQboToken),
        fork(watchUpsertQboCustomer),
        fork(watchUpsertQboTax),
        fork(watchUpsertQboIncomeAccount),
        fork(watchUpsertQboClearingAccount),
        fork(watchSyncStartInvoices),
        fork(watchSyncStartPayment),
        fork(watchUpsertQboAccountReceivable),
        fork(watchSyncInvoiceToQBO),
        fork(watchGetTaxList)
    ]);
}