/* eslint-disable require-yield */
/* eslint-disable no-return-await */
import { all, fork, takeEvery } from 'redux-saga/effects';
import { SHOW_SUCCESS_MESSAGE, SHOW_ERROR_MESSAGE } from 'constants/ActionTypes';
import { NotificationManager } from 'react-notifications';

function* showSuccessMessageSaga(action) {
  try {
    NotificationManager.success(action.payload.body, action.payload.title);
  } catch (err) {
    // TODO: universal error notifier!
  }
}

function* showErrorMessageSaga(action) {
  try {
    NotificationManager.error(action.payload.body, action.payload.title);
  } catch (err) {
    // TODO: universal error notifier!
  }
}

export function* showSuccessMessageSagaLink() {
  yield takeEvery(SHOW_SUCCESS_MESSAGE, showSuccessMessageSaga);
}

export function* showErrorMessageSagaLink() {
  yield takeEvery(SHOW_ERROR_MESSAGE, showErrorMessageSaga);
}

export default function* rootSaga() {
  yield all([fork(showSuccessMessageSagaLink), fork(showErrorMessageSagaLink)]);
}
