import pink from '@material-ui/core/colors/pink';

export default {
  palette: {
    primary: {
      dark: '#404352',
      main: '#64a6f4',
      light: '#D6E1F2',
      blue: '#157FF2',
      contrastText: '#fff'
    },
    secondary: {
      light: pink[300],
      main: pink.A200,
      dark: pink[700],
      contrastText: '#fff'
    }
  },
  status: {
    danger: 'orange'
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    }
  }
};
