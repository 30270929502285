import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { userSignOut } from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import { NavLink } from 'react-router-dom';

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        {this.props.authUser && this.props.authUser.avatar ? (
          <Avatar
            alt="..."
            src={`${
              this.props.authUser && this.props.authUser.avatar ? this.props.authUser.avatar : ''
            }`}
            className="user-avatar "
          />
        ) : (
          <div className="avatarContainer d-flex align-items-center justify-content-end mr-2">
            <div>
              <div className="noAvatar">
                {this.props.authUser && this.props.authUser.firstName
                  ? this.props.authUser.firstName.charAt(0)
                  : ''}
{' '}
                {this.props.authUser && this.props.authUser.lastName
                  ? this.props.authUser.lastName.charAt(0)
                  : ''}
              </div>
            </div>
          </div>
        )}

        <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>
            {this.props.authUser && this.props.authUser.firstName ? (
              this.props.authUser.firstName
            ) : (
              <IntlMessages id="users.yourAccount" />
            )}
{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              {this.props.authUser && this.props.authUser.lastName
                ? this.props.authUser.lastName
                : ' '}
              <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
            </span>
          </h4>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        >
          <NavLink to={`/app/users/profile/${this.props.authUser ? this.props.authUser.uuid : ''}`}>
            <MenuItem onClick={this.handleRequestClose}>
              <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
              <IntlMessages id="popup.profile" />
            </MenuItem>
          </NavLink>
          <NavLink to="/app/company">
            <MenuItem onClick={this.handleRequestClose}>
              <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
              <IntlMessages id="pages.companySettings" />
            </MenuItem>
          </NavLink>
          <MenuItem
            onClick={() => {
              this.handleRequestClose();
              this.props.userSignOut();
            }}
          >
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

            <IntlMessages id="popup.logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale } = settings;
  const { authUser } = auth;
  return { locale, authUser };
};
export default connect(
  mapStateToProps,
  { userSignOut },
)(UserInfo);
