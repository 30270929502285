import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'assets/vendors/style';
import MainApp from 'app/index';
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';
import defaultTheme from './themes/defaultTheme';
import AppLocale from '../lngProvider';

import SupplierToS from './SupplierToS';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import UnlockAccount from './UnlockAccount';
import RejectInvite from './RejectInvite';
import RequestUnlock from './RequestUnlock';
import { setInitUrl, checkActiveUser } from '../actions/Auth';

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location },
          }}
        />
      )}
  />
);

class App extends Component {
  componentWillMount() {
    window.addEventListener('storage', (event) => {
      if (event.key === 'refreshNeeded' && event.newValue == 'true') {
        localStorage.setItem('refreshNeeded', 'false'); // Reset the flag
        // eslint-disable-next-line no-restricted-globals
        location.reload(); // Refresh the page
      }
    });

    this.props.checkActiveUser();
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }

    if (localStorage.getItem('refreshNeeded') == 'true') {
      localStorage.setItem('refreshNeeded', 'false'); // Reset the flag
      window.location.reload(true)// Refresh the page
    }

  }

  render() {
    const { match, location, locale, authUser, initURL, isDirectionRTL } = this.props;
    if (location.pathname === '/') {
      if (authUser === null) {
        return <Redirect to="/signin" />;
      }
      if (
        initURL === '' ||
        initURL === '/' ||
        initURL === '/signin' ||
        initURL.indexOf('app') === -1
      ) {
        return <Redirect to="/app/accounts" />;
      }
      return <Redirect to={initURL} />;
    }
    const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
            <RTL>
              <div className="app-main">
                <Switch>
                  <RestrictedRoute
                    path={`${match.url}app`}
                    authUser={authUser}
                    component={MainApp}
                  />
                  <Route path="/signin" component={SignIn} />
                  <Route path="/supplier-tos/:id/:name" component={SupplierToS} />
                  <Route path="/signup" component={SignUp} />
                  <Route path="/forgotpassword" component={ForgotPassword} />
                  <Route path="/resetpassword" component={ResetPassword} />
                  <Route path="/requestunlock" component={RequestUnlock} />
                  <Route path="/unlock" component={UnlockAccount} />
                  <Route path="/rejectinvite" component={RejectInvite} />
                  <Route component={asyncComponent(() => import('components/Error404'))} />
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { sideNavColor, locale, isDirectionRTL } = settings;
  const { authUser, initURL } = auth;
  return { sideNavColor, locale, isDirectionRTL, authUser, initURL };
};

export default connect(
  mapStateToProps,
  { setInitUrl, checkActiveUser },
)(App);
