import {
  GET_INVOICE_LIST,
  GET_INVOICE,
  CLEAR_INVOICE_LIST,
  CLEAR_INVOICE,
  GET_PURCHASE_ORDER_FOR_INVOICE,
  GET_MORE_INVOICE_LIST,
  ADD_SINGLE_INVOICE,
  GET_ORGANIZATION_LIST
} from 'constants/ActionTypes';

const initialInvoices = {
  loading: false,
  invoiceLoading: false,
  allInvoices: [],
  purchaseOrdersForInvoice: [],
  invoice: {},
  orgList: []
};

const invoices = (state = initialInvoices, action) => {
  switch (action.type) {
    case GET_INVOICE_LIST:
      let allInvoices = action.payload.data.Invoices;
      return {
        ...state,
        allInvoices: allInvoices,
      };
    case GET_MORE_INVOICE_LIST:
      let invoices = action.payload.data.Invoices;
      return {
        ...state,
        allInvoices: state.allInvoices.concat(invoices)
      };
    case GET_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      };
    case GET_PURCHASE_ORDER_FOR_INVOICE:
      return {
        ...state,
        purchaseOrdersForInvoice: action.payload
      }
    case CLEAR_INVOICE_LIST:
      return {
        ...state,
        allInvoices: [],
        invoice: {},
      };
    case CLEAR_INVOICE:
      return {
        ...state,
        invoice: {},
      };
    case ADD_SINGLE_INVOICE:
      let allList = [...state.allInvoices]
      allList.unshift(action.payload)
      return {
        ...state,
        allInvoices: allList,
      };
    case GET_ORGANIZATION_LIST:

      return {
        ...state,
        orgList: action.payload,
      };
    default:
      return state;
  }
};

export default invoices;
