export function checkPasswords(password, confirmPassword) {
  let valid = true;
  let error = '';

  if (password !== confirmPassword) {
    valid = false;
    error = 'resetPassword.passwordMismatch';
  } else if (password.length < 8) {
    valid = false;
    error = 'resetPassword.passwordTooShort';
  } else {
    const requirementsPassed = passwordRequirements(password);
    if (!requirementsPassed) {
      valid = false;
      error = 'resetPassword.requirementsFailed';
    }
  }
  return { valid, error };
}

function passwordRequirements(password) {
  const lower = /[a-z]/; // use positive look ahead to see if at least one lower case letter exists
  const upper = /[A-Z]/; // use positive look ahead to see if at least one upper case letter exists
  const digit = /\d/; // use positive look ahead to see if at least one digit exists
  const symbol = /[-+_!@#$%^&*.,?]/; // use positive look ahead to see if at least one non-word character exists
  let count = 0;
  if (lower.test(password)) {
    count += 1;
  }
  if (upper.test(password)) {
    count += 1;
  }
  if (digit.test(password)) {
    count += 1;
  }
  if (symbol.test(password)) {
    count += 1;
  }
  if (count >= 3) {
    return true;
  }
  return false;
}
