import React from 'react';
const year = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="app-footer">
      <span className="d-inline-block">LabFellows &copy; {year} </span>
    </footer>
  );
};
export default Footer;
