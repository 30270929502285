/* eslint-disable require-yield */
/* eslint-disable no-return-await */
import { all, fork, call, put, takeEvery } from 'redux-saga/effects';
import configureStore, { history } from 'store';
import { parse } from 'json2csv';
import Papa from 'papaparse';
import moment from 'moment';
import {
  GET_CATALOG_ITEMS,
  UPLOAD_CATALOG,
  ADD_NEW_ITEM,
  DELETE_ITEM,
  UPDATE_CATALOG_ITEM,
  GET_CATALOG_HISTORIES,
  DOWNLOAD_CATALOG,
} from 'constants/ActionTypes';

import {
  updateAuthSupplier,
  successMessage,
  errorMessage,
  userSignOutSuccess,
  addCatalogItems,
  addCatalogHistories,
} from 'actions';
import { LFAPI } from 'util/LFAPI';
import translate from 'util/translator';

const fields = ['catalogNumber', 'name', 'description', 'uom', 'price'];
const opts = { fields };

function* getCatalogItems({ payload }) {
  try {
    const { accountUuid } = payload;
    const response = yield LFAPI.post('/catalogs/catalogItems', { accountUuid });
    const { items } = response.data.data;
    yield put(addCatalogItems({ items }));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf('Cannot create property \'headers\' on boolean \'false\'') > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(errorMessage('There was an error getting the items. Please try again.', 'Error'));
    }
  }
}

function* getCatalogHistories({ payload }) {
  try {
    const { accountUuid } = payload;
    const response = yield LFAPI.post('/catalogs/catalogHistory', { accountUuid });
    const { catalogs } = response.data.data;
    yield put(addCatalogHistories({ catalogs }));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf('Cannot create property \'headers\' on boolean \'false\'') > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(
        errorMessage(
          'There was an error getting the catalog histories. Please try again.',
          'Error',
        ),
      );
    }
  }
}

function* downloadCatalog({ payload }) {
  try {
    const { accountUuid, uuid } = payload;
    const response = yield LFAPI.post('/catalogs/catalog', { accountUuid, uuid });
    const { catalog } = response.data.data;
    const csv = parse(catalog.items, opts);
    const element = document.createElement('a');
    const file = new Blob([csv], {
      type: '.csv',
    });
    element.href = URL.createObjectURL(file);
    const date = moment(catalog.created_at).format('YYYY-MM-DD-HH-MM-SS');
    element.download = `catalog_history_${date}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setTimeout(() => {
      element.remove();
    }, 3000);
    // yield put(addCatalogHistories({ catalogs }));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf('Cannot create property \'headers\' on boolean \'false\'') > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(
        errorMessage(
          'There was an error getting the catalog histories. Please try again.',
          'Error',
        ),
      );
    }
  }
}

function* deleteItem({ payload }) {
  try {
    const { accountUuid, uuid } = payload;
    yield LFAPI.post('/catalogs/deleteItem', { accountUuid, uuid });
    const messageContent = yield translate('catalog.deleteItemSuccess');
    yield put(successMessage(messageContent, ''));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf('Cannot create property \'headers\' on boolean \'false\'') > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(errorMessage('There was an error deleting the item. Please try again.', 'Error'));
    }
  }
}

function* updateItem({ payload }) {
  try {
    const { accountUuid, uuid, updates } = payload;
    yield LFAPI.patch('/catalogs/catalogItem', { accountUuid, uuid, updates });
    const messageContent = yield translate('catalog.updateItemSuccess');
    yield put(successMessage(messageContent, ''));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf('Cannot create property \'headers\' on boolean \'false\'') > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(errorMessage('There was an error updating the item. Please try again.', 'Error'));
    }
  }
}

function* addNewItem({ payload }) {
  try {
    const { accountUuid, catalogNumber, name, description, uom, uuid, price } = payload;
    yield LFAPI.post('/catalogs/newItem', {
      accountUuid,
      catalogNumber,
      name,
      description,
      uom,
      uuid,
      price,
    });
    const messageContent = yield translate('catalog.addItemSuccess');
    yield put(successMessage(messageContent, ''));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf('Cannot create property \'headers\' on boolean \'false\'') > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(errorMessage('There was an error adding the item. Please try again.', 'Error'));
    }
  }
}

function* uploadCatalogSaga({ payload }) {
  const error = false;
  const { uploadData, uploadHeaders, accountUuid } = payload;
  try {
    yield LFAPI.post('/catalogs/uploadCatalog', {
      uploadData,
      uploadHeaders,
      accountUuid,
    });
    yield call(history.push(`/app/catalogs/view/${accountUuid}`));
  } catch (err) {
    if (err.message.indexOf('argument fn is undefined or null') > -1) {
      const messageContent = yield translate('catalog.uploadCatalogSucces');
      yield put(successMessage(messageContent, ''));
    } else if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf('Cannot create property \'headers\' on boolean \'false\'') > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(
        errorMessage('There was an error uploading the catalog. Please try again.', 'Error'),
      );
    }
  }
}

export function* addNewItemLink() {
  yield takeEvery(ADD_NEW_ITEM, addNewItem);
}

export function* getItemsLink() {
  yield takeEvery(GET_CATALOG_ITEMS, getCatalogItems);
}

export function* deleteItemlink() {
  yield takeEvery(DELETE_ITEM, deleteItem);
}

export function* updateItemLink() {
  yield takeEvery(UPDATE_CATALOG_ITEM, updateItem);
}

export function* uploadCatalogSagaLink() {
  yield takeEvery(UPLOAD_CATALOG, uploadCatalogSaga);
}

export function* getCatalogHistoriesLink() {
  yield takeEvery(GET_CATALOG_HISTORIES, getCatalogHistories);
}

export function* downloadCatalogLink() {
  yield takeEvery(DOWNLOAD_CATALOG, downloadCatalog);
}

export default function* rootSaga() {
  yield all([
    fork(uploadCatalogSagaLink),
    fork(addNewItemLink),
    fork(getItemsLink),
    fork(deleteItemlink),
    fork(updateItemLink),
    fork(getCatalogHistoriesLink),
    fork(downloadCatalogLink),
  ]);
}
