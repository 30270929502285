/* eslint-disable no-case-declarations */
import {
  UPDATE_USERS,
  DELETE_USER_FROM_STORE,
  UPDATE_ALL_USERS_IN_STORE,
} from 'constants/ActionTypes';

const initialUsers = {
  allUsers: [],
};

const users = (state = initialUsers, action) => {
  switch (action.type) {
    case UPDATE_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case UPDATE_ALL_USERS_IN_STORE:
      return {
        ...state,
        allUsers: action.payload.allUsers,
      };
    case DELETE_USER_FROM_STORE:
      let { allUsers } = state;
      allUsers = allUsers.filter(user => {
        return user.uuid !== action.payload.uuid;
      });
      return {
        ...state,
        allUsers,
      };
    default:
      return state;
  }
};

export default users;
