/* eslint-disable require-yield */
/* eslint-disable no-return-await */
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  GET_AUTH_SUPPLIER_SAGA,
  UPDATE_AUTH_SUPPLIER_SAGA,
  GET_ALL_SUPPLIER_SAGAS,
  CREATE_SUPPLIER,
} from 'constants/ActionTypes';

import {
  addAuthSupplier,
  updateAuthSupplier,
  addAllSuppliers,
  successMessage,
  errorMessage,
  userSignOutSuccess,
} from 'actions';
import { LFAPI } from 'util/LFAPI';
import translate from 'util/translator';

function* createSupplierSaga({ payload }) {
  try {
    const { name } = payload;
    const response = yield LFAPI.post('/suppliers/create', { name });
    const messageContent = yield translate('company.createSupplierSuccess');
    yield put(successMessage(messageContent));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf("Cannot create property 'headers' on boolean 'false'") > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      const messageContent = yield translate('company.createSupplierError');
      yield put(errorMessage(messageContent, 'Error'));
    }
  }
}

function* getAllSuppliersSaga() {
  try {
    const response = yield LFAPI.get('/suppliers/suppliers');
    const { suppliers } = response.data.data;
    yield put(addAllSuppliers({ suppliers }));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf("Cannot create property 'headers' on boolean 'false'") > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(
        errorMessage('There was an error getting the suppliers. Please try again.', 'Error'),
      );
    }
  }
}

function* getAuthSupplierSaga() {
  try {
    const response = yield LFAPI.get('/suppliers/supplier');
    const { supplier: authSupplier } = response.data.data;
    yield put(addAuthSupplier({ authSupplier }));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf("Cannot create property 'headers' on boolean 'false'") > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(
        errorMessage('There was an error getting the supplier. Please try again.', 'Error'),
      );
    }
  }
}

function* updateSupplierSaga(action) {
  try {
    const { updates } = action.payload;
    yield LFAPI.patch('/suppliers/supplier', { updates });
    yield put(updateAuthSupplier({ updates }));
    const messageContent = yield translate('company.successfullyUpdated');
    yield put(successMessage(messageContent));
  } catch (err) {
    console.log("err", err.response.data.message)
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf("Cannot create property 'headers' on boolean 'false'") > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(
        errorMessage('There was an error getting the supplier. Please try again.', 'Error'),
      );
    }
  }
}

export function* getAuthSupplierLink() {
  yield takeEvery(GET_AUTH_SUPPLIER_SAGA, getAuthSupplierSaga);
}

export function* updateAuthSupplierLink() {
  yield takeEvery(UPDATE_AUTH_SUPPLIER_SAGA, updateSupplierSaga);
}

export function* getAllSuppliersLink() {
  yield takeEvery(GET_ALL_SUPPLIER_SAGAS, getAllSuppliersSaga);
}
export function* createSupplierSagaLink() {
  yield takeEvery(CREATE_SUPPLIER, createSupplierSaga);
}

export default function* rootSaga() {
  yield all([
    fork(getAuthSupplierLink),
    fork(updateAuthSupplierLink),
    fork(getAllSuppliersLink),
    fork(createSupplierSagaLink),
  ]);
}
