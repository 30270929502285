import React from 'react';
import LFConfirm from 'components/LabFellowsConfirm';
import { injectIntl } from 'react-intl';

class LFExpiraitionTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationBox_open: false,
      confirmationBox_label: props.intl.formatMessage({ id: 'accounts.yourSessionIsExpiring' }),
      confirmationBox_title: props.intl.formatMessage({ id: 'accounts.yourSessionIsExpiring' }),
      confirmationBox_cancelText: props.intl.formatMessage({ id: 'accounts.logOut' }),
      confirmationBox_acceptText: props.intl.formatMessage({ id: 'accounts.stayLoggedIn' }),
      secondsUntilLogout: props.secondsUntilLogout || Infinity,
      warningThreshold: props.warningThreshold || -Infinity,
      countdownStarted: false,
      countdownText: '',
      interval: null,
    };
    this.loseASecond = this.loseASecond.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (
      // eslint-disable-next-line no-restricted-globals
      isNaN(prevProps.secondsUntilLogout) &&
      // eslint-disable-next-line no-restricted-globals
      !isNaN(this.props.secondsUntilLogout) &&
      this.props.secondsUntilLogout !== prevProps.secondsUntilLogout
    ) {
      const { secondsUntilLogout } = this.props;
      this.setState({ secondsUntilLogout });
    }
  }

  loseASecond() {
    let { secondsUntilLogout, countdownStarted, confirmationBox_open } = this.state;
    countdownStarted = true;
    if (secondsUntilLogout) {
      secondsUntilLogout -= 1;
      let minutes = 0;
      let seconds = secondsUntilLogout;
      if (secondsUntilLogout === 0) {
        return this.props.handleCancel();
      }
      if (secondsUntilLogout <= this.state.warningThreshold) {
        confirmationBox_open = true;
      }
      if (secondsUntilLogout >= 60) {
        minutes = Math.floor(secondsUntilLogout / 60);
        seconds = Math.floor(secondsUntilLogout % 60);
      }
      let countdownText = `${seconds} ${this.props.intl.formatMessage({ id: 'accounts.seconds' })}`;
      if (minutes > 0) {
        countdownText = `${minutes} ${this.props.intl.formatMessage({
          id: 'accounts.minutesAnd',
        })} ${countdownText}`;
      }
      this.setState({ secondsUntilLogout, countdownText, countdownStarted, confirmationBox_open });
    }
  }

  render() {
    if (!this.state.countdownStarted && this.state.secondsUntilLogout !== Infinity) {
      this.loseASecond();
      this.setState({ interval: setInterval(this.loseASecond, 1000) });
    }
    const confirmationBox_description = `${this.props.intl.formatMessage({
      id: 'accounts.areYouStillThere',
    })} ${this.state.countdownText} ${this.props.intl.formatMessage({
      id: 'accounts.clickToStayLoggedIn',
    })}`;

    return (
      <div>
        <LFConfirm
          open={this.state.confirmationBox_open}
          label={this.state.confirmationBox_label}
          description={confirmationBox_description}
          title={this.state.confirmationBox_title}
          content={confirmationBox_description}
          cancelText={this.state.confirmationBox_cancelText}
          acceptText={this.state.confirmationBox_acceptText}
          handleAccept={() => {
            clearInterval(this.state.interval);
            this.setState({
              confirmationBox_open: false,
              secondsUntilLogout: Infinity,
              countdownStarted: false,
              interval: null,
            });
            this.props.handleAccept();
            setTimeout(() => {
              this.setState({ secondsUntilLogout: this.props.secondsUntilLogout });
            }, 1000);
          }}
          handleCancel={() => {
            clearInterval(this.state.interval);
            this.setState({
              confirmationBox_open: false,
              secondsUntilLogout: Infinity,
              countdownStarted: false,
              interval: null,
            });
            this.props.handleCancel();
          }}
          handleClose={() => {}}
        />
      </div>
    );
  }
}
export default injectIntl(LFExpiraitionTimer);
