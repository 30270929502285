import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const InjectMessage = props => {
  return <FormattedMessage {...props} id={!props.id ? 'appModule.loading' : props.id} />;
};

export default injectIntl(InjectMessage, {
  withRef: false
});
