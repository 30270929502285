import React from 'react';
import MuiTextField from '@material-ui/core/TextField';

const TextField = props => {
  return (
    <MuiTextField
      InputLabelProps={{
        style: {
          height: 40,
          top: '-6px',
        },
      }}
      InputProps={{
        style: {
          height: 40,
        }
      }}
      margin="normal"
      variant="outlined"
      fullWidth
      {...props}
    />
  )
};

export default TextField;

