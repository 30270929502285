const saveAuthToken = authToken => {
  try {
    localStorage.setItem('authToken', authToken);
  } catch (err) {
    throw new Error(`error setting auth token in local storage${err}`);
  }
};

const getAuthToken = () => {
  try {
    return localStorage.getItem('authToken');
  } catch (err) {
    throw new Error(`error getting auth token from local storage ${err}`);
  }
};

const deleteAuthToken = () => {
  try {
    return localStorage.removeItem('authToken');
  } catch (err) {
    throw new Error(`error deleting auth token from local storage ${err}`);
  }
};

export { saveAuthToken, getAuthToken, deleteAuthToken };
