import {
  GET_ACCOUNT,
  ADD_ALL_ACCOUNTS,
  GET_ALL_ACCOUNTS,
  ADD_ACCOUNT,
  UPDATE_ACCOUNT_SAGA,
  TEST_URLS,
} from 'constants/ActionTypes';

export const testUrls = ({ punchoutTestUrl, punchoutUrl, cxmlOrderUrl, cxmlTestUrl }) => {
  // console.log('testUrls:', punchoutTestUrl, punchoutUrl, cxmlOrderUrl, cxmlTestUrl);
  return {
    type: TEST_URLS,
    payload: { punchoutTestUrl, punchoutUrl, cxmlOrderUrl, cxmlTestUrl },
  };
};

export const addAllAccounts = accounts => {
  return {
    type: ADD_ALL_ACCOUNTS,
    payload: accounts,
  };
};

export const updateAccount = ({ updates, uuid }) => {
  return {
    type: UPDATE_ACCOUNT_SAGA,
    payload: { updates, uuid },
  };
};

export const getAllAccounts = () => {
  return {
    type: GET_ALL_ACCOUNTS,
    payload: {}
  };
};

export const getAccount = ({ accountUuid }) => {
  return {
    type: GET_ACCOUNT,
    payload: { accountUuid },
  };
};

export const addAccount = ({ account }) => {
  return {
    type: ADD_ACCOUNT,
    payload: account,
  };
};
