import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import queryString from 'query-string';
import { unlockAccount } from 'actions/Auth';

class UnlockAccount extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    // eslint-disable-next-line no-restricted-globals
    const urlParams = queryString.parse(location.search);
    if (!urlParams.t || !urlParams.email) {
      this.props.history.push('/signin?invalidUnlock=true');
    } else {
      this.props.unlockAccount(urlParams.email, urlParams.t);
    }
  }

  componentDidUpdate() {}

  render() {
    const { rejectUnlockToken } = this.props;

    return (
      <div className="app-login-container d-flex flex-column justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="d-flex text-center align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="LabFellows">
            <img
              style={{ maxWidth: '40%', marginBottom: '2rem' }}
              src={require('assets/images/LF-logo-name-horizontal-black.svg')}
              alt="LabFellows"
              title="LabFellows"
            />
          </Link>
        </div>

        <div className="app-login-main-content app-forgot-password-main-content">
          <div className="app-login-content">
            <div className="d-flex flex-column align-items-center">
              <div>
                <i className="fas fa-check-circle color-green text-50 mb-4" />
              </div>
              <div className="app-login-header mb-4">
                <h1 className="forgotPassword extra-bold text-28 mb-4">
                  <IntlMessages id="unlockAccount.accountUnlocked" />
                </h1>
                <span className="mt-4 text-16">
                  <IntlMessages id="unlockAccount.accountUnlockedDescription" />
                </span>
              </div>
            </div>

            <Button
              className="btn-full-width"
              onClick={() => {
                this.props.history.push('/');
              }}
              variant="contained"
              color="primary"
            >
              <IntlMessages id="appModule.signIn" />
            </Button>
          </div>
        </div>
        {rejectUnlockToken && this.props.history.push('/signin?rejectedUnlock=true')}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { showMessage, rejectUnlockToken } = auth;
  return { showMessage, rejectUnlockToken };
};

export default connect(
  mapStateToProps,
  { unlockAccount },
)(UnlockAccount);
