import {
  GET_CATALOG_ITEMS,
  ADD_CATALOG_ITEMS,
  ADD_NEW_ITEM,
  DELETE_ITEM,
  UPDATE_CATALOG_ITEM,
  UPLOAD_CATALOG,
  ADD_CATALOG_HISTORIES,
  GET_CATALOG_HISTORIES,
  DOWNLOAD_CATALOG,
} from 'constants/ActionTypes';

export const addCatalogItems = items => {
  return {
    type: ADD_CATALOG_ITEMS,
    payload: { items },
  };
};

export const getCatalogHistories = ({ accountUuid }) => {
  return {
    type: GET_CATALOG_HISTORIES,
    payload: { accountUuid },
  };
};

export const downloadCatalog = ({ accountUuid, uuid }) => {
  return {
    type: DOWNLOAD_CATALOG,
    payload: { accountUuid, uuid },
  };
};

export const addCatalogHistories = catalogs => {
  return {
    type: ADD_CATALOG_HISTORIES,
    payload: { catalogs },
  };
};

export const getCatalogItems = ({ accountUuid }) => {
  return {
    type: GET_CATALOG_ITEMS,
    payload: { accountUuid },
  };
};

export const addNewItem = ({ accountUuid, catalogNumber, name, description, uom, price, uuid }) => {
  return {
    type: ADD_NEW_ITEM,
    payload: { accountUuid, catalogNumber, name, description, uom, price, uuid },
  };
};

export const uploadCatalog = ({ uploadData, uploadHeaders, accountUuid }) => {
  return {
    type: UPLOAD_CATALOG,
    payload: { uploadData, uploadHeaders, accountUuid },
  };
};

export const deleteItem = ({ accountUuid, uuid }) => {
  return {
    type: DELETE_ITEM,
    payload: { accountUuid, uuid },
  };
};

export const updateItem = ({ accountUuid, uuid, updates }) => {
  return {
    type: UPDATE_CATALOG_ITEM,
    payload: { accountUuid, uuid, updates },
  };
};
