import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import { userSignOut, getAuthSupplier, checkActiveUser } from 'actions';
import { isIOS, isMobile } from 'react-device-detect';
import TopNav from 'components/TopNav';
import { NotificationContainer } from 'react-notifications';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';
import { refreshAuthToken } from 'util/LFAPI';
import LFExpirationTimer from 'components/LabFellowsExpirationTimer';
import asyncComponent from '../util/asyncComponent';
import ReTOSBox from 'components/ReTOSBox/index';
import { checkIfAdmin } from 'util/privileges';
import { Redirect } from 'react-router-dom';
import { getAuthToken } from 'util/LocalStorage';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getAuthSupplier();
  }

  render() {
    const { match, drawerType, navigationStyle, horizontalNavPosition } = this.props;
    // eslint-disable-next-line no-nested-ternary
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'fixed-drawer'
      : drawerType.includes(COLLAPSED_DRAWER)
        ? 'collapsible-drawer'
        : 'mini-drawer';

    // set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height');
    }
    // note the session exp time is different than the token exp time.
    // the session exp time is: (2x the token valid length) + issued at time
    // in other words - sessions are twice as long as tokens
    const { authUser } = this.props;

    let authToken=getAuthToken()
    console.log(authUser,'initial authToken ',authToken)
    
    if (authUser === null) {
      return <Redirect to="/signin" />;
    }
    return (
      <div
        className={`app-container ${drawerStyle} ${this.props.authUser && this.props.authUser.email ? '' : 'd-none'
          }`}
      >
        {
          // authToken && authUser && checkIfAdmin(authUser) && <ReTOSBox />
        }
        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''
              }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && <TopNav styleName="app-top-header" />}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content" >
              <Switch>
                <Route
                  path={`${match.url}/users`}
                  component={asyncComponent(() => import('../routes/User/index'))}
                />
                <Route
                  path={`${match.url}/accounts`}
                  component={asyncComponent(() => import('../routes/Accounts/index'))}
                />
                <Route
                  path={`${match.url}/catalogs`}
                  component={asyncComponent(() => import('../routes/AccountCatalogs/index'))}
                />
                <Route
                  path={`${match.url}/analytics`}
                  component={asyncComponent(() => import('../routes/Analytics/TAT'))}
                />
                <Route
                  path={`${match.url}/company`}
                  component={asyncComponent(() => import('../routes/Company/index'))}
                />
                <Route
                  path={`${match.url}/chartOfAccounts`}
                  component={asyncComponent(() => import('../routes/ChartOfAccounts/index'))}
                />
                <Route
                  path={`${match.url}/newSupplier`}
                  component={asyncComponent(() => import('../routes/NewSupplier/index'))}
                />
                <Route
                  path={`${match.url}/orders`}
                  component={asyncComponent(() => import('../routes/Orders/index'))}
                />
                <Route
                  path={`${match.url}/invoices`}
                  component={asyncComponent(() => import('../routes/Invoices/index'))}
                />
                <Route
                  path={`${match.url}/payments`}
                  component={asyncComponent(() => import('../routes/Payments/index'))}
                />
                <Route
                  path={`${match.url}/integrations`}
                  component={asyncComponent(() => import('../routes/Integrations/index'))}
                />
                <Route component={asyncComponent(() => import('components/Error404'))} />
              </Switch>
            </div>
            <Footer />
          </main>
        </div>
        <NotificationContainer />
        {
          authUser && authUser.iat ? (
            <LFExpirationTimer
              secondsUntilLogout={Math.floor(
                authUser.iat + (authUser.exp - authUser.iat) * 2 - Date.now() / 1000,
              )}
              warningThreshold={120}
              handleAccept={() => {
                this.props.checkActiveUser();
              }}
              handleCancel={() => {
                this.props.userSignOut();
              }}
            />
          ) : (
            <></>
          )
        }
      </div >
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  const { authUser } = auth;
  return { drawerType, navigationStyle, horizontalNavPosition, authUser };
};
export default withRouter(
  connect(
    mapStateToProps,
    { userSignOut, getAuthSupplier, checkActiveUser },
  )(App),
);
