/* @flow */

import Icon from '@material-ui/core/Icon';
import { withStyles } from 'material-ui/styles';

const styles = theme => ({
    root: {
        fontFamily: 'Material Icons !important'
    }
})
export default withStyles(styles)(Icon);
