import {
    GET_CHART_OF_ACCOUNT_LIST,
    GET_ALL_CHART_OF_ACCOUNTS,
    MAKE_LOADER_ACTIVE
} from 'constants/ActionTypes';

export const getAllChartOfAccounts = () => {
    return {
        type: GET_ALL_CHART_OF_ACCOUNTS,
        payload: {},
    };
};

export const updateAllChartOfAccountsInStore = data => {
    return {
        type: GET_CHART_OF_ACCOUNT_LIST,
        payload: data,
    };
};

export const makeLoaderActive = data => {
    return {
        type: MAKE_LOADER_ACTIVE,
        payload: data,
    };
};