import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_EMAIL_REQUEST,
  CHECK_ACTIVE_USER,
  UNLOCK_ACCOUNT_EMAIL_REQUEST,
  UNLOCK_ACCOUNT,
  UPDATE_AUTH_USER,
  NEW_USER_SET_PASSWORD_SAGA,
} from 'constants/ActionTypes';

export const setNewUserPassword = ({ email, password, confirmPassword, confirmationToken }) => {
  return {
    type: NEW_USER_SET_PASSWORD_SAGA,
    payload: { email, password, confirmPassword, confirmationToken },
  };
};

export const updateAuthUser = ({ updates }) => {
  return {
    type: UPDATE_AUTH_USER,
    payload: { updates },
  };
};
export const checkActiveUser = () => {
  return {
    type: CHECK_ACTIVE_USER,
  };
};
export const userSignUp = user => {
  return {
    type: SIGNUP_USER,
    payload: user,
  };
};
export const userSignIn = user => {
  return {
    type: SIGNIN_USER,
    payload: user,
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER,
  };
};
export const userSignUpSuccess = authUser => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser,
  };
};

export const userSignInSuccess = authUser => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser,
  };
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  };
};

export const resetPassword = (email, password, confirmPassword, resetToken) => {
  return {
    type: RESET_PASSWORD,
    payload: { email, password, confirmPassword, resetToken },
  };
};

export const resetPasswordEmailRequest = email => {
  return {
    type: RESET_PASSWORD_EMAIL_REQUEST,
    payload: { email },
  };
};

export const unlockAccountEmailRequest = email => {
  return {
    type: UNLOCK_ACCOUNT_EMAIL_REQUEST,
    payload: { email },
  };
};

export const unlockAccount = (email, unlockToken) => {
  return {
    type: UNLOCK_ACCOUNT,
    payload: { email, unlockToken },
  };
};
export const showAuthMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};
export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url,
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
