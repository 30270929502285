import { GET_PAYMENT_PROFILE, GET_PAYMENT_PROFILE_SAGA, UPDATE_PAYMENT_PROFILE_SAGA, UPDATE_PAYMENT_PROFILE, GET_PAYMENT_HISTORY, GET_PAYMENT_HISTORY_SAGA, UPDATE_PAYMENT_DETAIL } from 'constants/ActionTypes';


export const getPaymentProfile = (data) => {
  return {
    type: GET_PAYMENT_PROFILE,
    payload: data
  }
}

export const getPaymentProfileSaga = (uuid) => {
  return {
    type: GET_PAYMENT_PROFILE_SAGA,
    payload: uuid,
  }
}

export const updatePaymentProfileSaga = ({ updates }) => {
  return {
    type: UPDATE_PAYMENT_PROFILE_SAGA,
    payload: { updates },
  };
};

export const updatePaymentProfile = ({ updates }) => {
  return {
    type: UPDATE_PAYMENT_PROFILE,
    payload: { updates },
  };
};

export const getPaymentHistory = (data) => {
  return {
    type: GET_PAYMENT_HISTORY,
    payload: data
  }
}

export const getPaymentHistorySaga = (uuid) => {
  return {
    type: GET_PAYMENT_HISTORY_SAGA,
    payload: uuid,
  }
}

export const updatePaymentDetail = (data) => {
  return {
    type: UPDATE_PAYMENT_DETAIL,
    payload: data,
  };
};