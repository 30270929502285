import { ADD_AUTH_SUPPLIER, UPDATE_AUTH_SUPPLIER, ADD_ALL_SUPPLIERS } from 'constants/ActionTypes';

const initialSupplierInfo = {
  allSuppliers: [],
  authSupplier: {
    name: '',
    config: {},
    catalogMode: '',
  },
};

const suppliers = (state = initialSupplierInfo, action) => {
  switch (action.type) {
    case ADD_AUTH_SUPPLIER:
      return {
        ...state,
        authSupplier: action.payload.authSupplier,
      };
    case ADD_ALL_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload.suppliers,
      };
    case UPDATE_AUTH_SUPPLIER:
      let config = { ...state.authSupplier.config };
      if (action.payload.updates.config) {
        config = { ...config, ...action.payload.updates.config };
        delete action.payload.updates.config;
      }
      return {
        ...state,
        authSupplier: {
          ...state.authSupplier,
          ...action.payload.updates,
          config,
        },
      };
    default:
      return state;
  }
};

export default suppliers;
