import data from 'components/ToDoCard/data';
import {
    GET_ACCOUNT_RECEIVIBALE_SAGA,
    GET_QBO_ALL_INVOICES,
    GET_SUPPLIER_ACCOUNTS_SAGA,
    GET_QBO_CUSTOMERS,
    GET_QBO_STATUS_SAGA,
    GET_QBO_STATUS,
    GET_QBO_CUSTOMERS_SAGA,
    DELETE_QBO_TOKEN_SAGA,
    UPSERT_QBO_CUSTOMER_SAGA,
    GET_QBO_ACCOUNT_RECEIVABLES,
    GET_QBO_INCOME,
    GET_QBO_CLEAR,
    UPSERT_QBO_INCOME_ACCOUNT_SAGA,
    UPSERT_QBO_INCOME_ACCOUNT,
    UPSERT_QBO_CLEARING_ACCOUNT_SAGA,
    UPSERT_QBO_ACCOUNT_RECEIVABLE_SAGA,
    GET_QBO_ALL_INVOICES_SAGA,
    SYNC_INVOICES_TO_QBO,
    GET_SYNC_INVOICES_TO_QBO_STATUS,
    GET_QBO_TAX_SAGA,
    GET_QBO_TAX_LIST,
    UPSERT_QBO_TAX_SAGA,
    START_INVOICES_SYNC,
    START_PAYMENT_SYNC,
    PAYMENT_SYNC_STATUS,
    INVOICE_SYNC_STATUS
} from 'constants/ActionTypes';

export const getQBOStatusSaga = () => {
    return {
        type: GET_QBO_STATUS_SAGA,
        payload: {},
    }
}

export const getQBOCustomersSaga = () => {
    return {
        type: GET_QBO_CUSTOMERS_SAGA,
        payload: {}
    }
}

export const getQBOAllInvoices = (data) => {
    return {
        type: GET_QBO_ALL_INVOICES,
        payload: data
    }
}

export const getQBOAllInvoicesSaga = (data) => {
    return {
        type: GET_QBO_ALL_INVOICES_SAGA,
        payload: data
    }
}

export const getSupplierAccounts = (data) => {
    return {
        type: GET_SUPPLIER_ACCOUNTS_SAGA,
        payload: data
    }
}

export const deleteQboTokenSaga = () => {
    return {
        type: DELETE_QBO_TOKEN_SAGA,
        payload: {}
    }
}

export const upsertCustomerToSupplierAccountSaga = (data) => {
    return {
        type: UPSERT_QBO_CUSTOMER_SAGA,
        payload: data
    }
}

export const upsertTaxToQboSaga = (data) => {
    return {
        type: UPSERT_QBO_TAX_SAGA,
        payload: data
    }
}

export const setQBOIncomeAccountSaga = (data) => {
    return {
        type: UPSERT_QBO_INCOME_ACCOUNT_SAGA,
        payload: data
    }
}

export const setQBOClearingAccountSaga = (data) => {
    return {
        type: UPSERT_QBO_CLEARING_ACCOUNT_SAGA,
        payload: data
    }
}

export const setQBOAccountReceivableSaga = (data) => {
    return {
        type: UPSERT_QBO_ACCOUNT_RECEIVABLE_SAGA,
        payload: data
    }
}

export const InvoiceSynStart = (data) => {
    return {
        type: START_INVOICES_SYNC,
        payload: data
    }
}
export const paymentSynStart = (data) => {
    return {
        type: START_PAYMENT_SYNC,
        payload: data
    }
}

export const setQBOIncomeAccount = (data) => {
    return {
        type: UPSERT_QBO_INCOME_ACCOUNT,
        payload: data
    }
}

export const getAccountReceiviable = (data) => {
    return {
        type: GET_ACCOUNT_RECEIVIBALE_SAGA,
        payload: data
    }
}

export const getQBOStatus = (data) => {
    return {
        type: GET_QBO_STATUS,
        payload: data
    }
}

export const getQBOAccountReceivable = (data) => {
    return {
        type: GET_QBO_ACCOUNT_RECEIVABLES,
        payload: data
    }
}
export const getQBOIncome = (data) => {
    return {
        type: GET_QBO_INCOME,
        payload: data
    }
}

export const getQBOClear = (data) => {
    return {
        type: GET_QBO_CLEAR,
        payload: data
    }
}


export const getQBOCustomers = (data) => {
    return {
        type: GET_QBO_CUSTOMERS,
        payload: data
    }
}

export const getQBOTaxList = (data) => {
    return {
        type: GET_QBO_TAX_LIST,
        payload: data
    }
}
export const setPaymentSyncStatus = (data) => {
    return {
        type: PAYMENT_SYNC_STATUS,
        payload: data
    }
};

export const setInvoiceSyncStatus = (data) => {
    return {
        type: INVOICE_SYNC_STATUS,
        payload: data
    }
};

export const syncInvoicesToQBO = (data) => {
    return {
        type: SYNC_INVOICES_TO_QBO,
        payload: data
    }
}


export const getSyncInvoicestoQBOStatus = (data) => {
    return {
        type: GET_SYNC_INVOICES_TO_QBO_STATUS,
        payload: data
    }
}

export const getQBOTaxSaga = () => {
    return {
        type: GET_QBO_TAX_SAGA,
        payload: {}
    }
}