import { GET_TAX_LIST } from 'constants/ActionTypes';

const initialTax = {
  taxList: [],
};

const taxRegime = (state = initialTax, action) => {
  switch (action.type) {
    case GET_TAX_LIST:
      let taxList = action.payload.data.taxRegimes;
      return {
        ...state,
        taxList
      };
    default:
      return state;
  }
};

export default taxRegime;