/* eslint-disable no-return-await */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  RESET_PASSWORD,
  RESET_PASSWORD_EMAIL_REQUEST,
  CHECK_ACTIVE_USER,
  UNLOCK_ACCOUNT_EMAIL_REQUEST,
  UNLOCK_ACCOUNT,
  REJECT_UNLOCK_TOKEN,
  NEW_USER_SET_PASSWORD_SAGA,
} from 'constants/ActionTypes';
import { showAuthMessage, userSignInSuccess, userSignOutSuccess, successMessage } from 'actions';
import { LFAPINoAuth, refreshAuthToken } from 'util/LFAPI';
import { saveAuthToken, getAuthToken, deleteAuthToken } from 'util/LocalStorage';
import translate from 'util/translator';
import decodeJWT from 'jwt-decode';

const baseURL = process.env.REACT_APP_API_URL;

function* setNewUserPasswordSaga({ payload }) {
  try {
    const { email, password, confirmPassword, confirmationToken } = payload;
    const response = yield LFAPINoAuth.post('/auth/newUserNewPassword', {
      email,
      password,
      confirmPassword,
      confirmationToken,
    });
    saveAuthToken(response.headers.authorization);
    yield put(userSignInSuccess(response.data.data.user));
    const messageContent = yield translate('users.newUserPasswordSuccess');
    return yield put(successMessage(messageContent, ''));
  } catch (error) {
    yield put(showAuthMessage('The token is invalid'));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const response = yield LFAPINoAuth.post('/auth/login', {
      email,
      password,
    });
    saveAuthToken(response.headers.authorization);
    yield refreshAuthToken();
    const authToken = getAuthToken();
    const decodedUser = decodeJWT(authToken);
    decodedUser.firstName = decodedUser.firstName || decodedUser.first_name || null;
    decodedUser.lastName = decodedUser.lastName || decodedUser.last_name || null;
    localStorage.setItem('userDetails', JSON.stringify(decodedUser))
    return yield put(userSignInSuccess(decodedUser));
  } catch (error) {
    let errorMessage = `${error.message} ` || 'An unknown error has occurred';
    if (errorMessage.indexOf('423') > -1) {
      const errorText = yield translate('accounts.accountLocked');
      errorMessage = (
        <NavLink to="/requestUnlock" style={{ color: 'white' }}>
          {errorText}
        </NavLink>
      );
    } else if (errorMessage.indexOf('401') > -1) {
      errorMessage = yield translate('accounts.invalidCredentials');
    }
    localStorage.removeItem('userDetails')
    yield put(showAuthMessage(errorMessage));
  }
}

function* signOut() {
  try {
    const authorization = getAuthToken();
    if (authorization) {
      const signOut = axios.create({
        baseURL,
        timeout: 500,
        headers: { authorization },
      });
      signOut.delete('/auth/logout', {});
      deleteAuthToken();
    }
    localStorage.removeItem('userDetails')
    yield put(userSignOutSuccess());
  } catch (error) {
    deleteAuthToken();
    localStorage.removeItem('userDetails')
    yield put(userSignOutSuccess());
    yield put(showAuthMessage(error));
  }
}

function* requestPasswordResetEmail({ payload }) {
  try {
    const { email } = payload;
    const response = yield LFAPINoAuth.post('/auth/sendResetPasswordEmail', {
      email,
    });
    if (!response || response.status !== 200) {
      yield put(showAuthMessage(response.data.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* requestAccountUnlockEmail({ payload }) {
  try {
    const { email } = payload;
    const response = yield LFAPINoAuth.post('/auth/sendAccountUnlockEmail', {
      email,
    });
    if (!response || response.status !== 200) {
      yield put(showAuthMessage(response.data.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* resetPassword({ payload }) {
  try {
    const { email, password, confirmPassword, resetToken } = payload;
    const response = yield LFAPINoAuth.post('/auth/resetPassword', {
      email,
      password,
      confirmPassword,
      resetToken,
    });
    saveAuthToken(response.headers.authorization);
    return yield put(userSignInSuccess(response.data.data.user));
  } catch (error) {
    yield put(showAuthMessage('The token is invalid'));
  }
}

function* unlockAccount({ payload }) {
  try {
    const { email, unlockToken } = payload;
    yield LFAPINoAuth.post('/auth/unlockAccount', {
      email,
      unlockToken,
    });
  } catch (error) {
    yield put({ type: REJECT_UNLOCK_TOKEN });
  }
}

function* checkForActiveLogin() {
  try {
    const authorization = getAuthToken();
    if (authorization) {
      yield refreshAuthToken();
      const authToken = getAuthToken();
      const decodedUser = decodeJWT(authToken);
      decodedUser.firstName = decodedUser.firstName || decodedUser.first_name || null;
      decodedUser.lastName = decodedUser.lastName || decodedUser.last_name || null;
      return yield put(userSignInSuccess(decodedUser));
    }
    return yield put(userSignOutSuccess());
  } catch (err) {
    yield put(userSignOutSuccess());
  }
}

export function* checkIfTokenActive() {
  yield takeEvery(CHECK_ACTIVE_USER, checkForActiveLogin);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* resetPasswordLink() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* requestPasswordResetEmailLink() {
  yield takeEvery(RESET_PASSWORD_EMAIL_REQUEST, requestPasswordResetEmail);
}

export function* requestUnlockAccountEmailLink() {
  yield takeEvery(UNLOCK_ACCOUNT_EMAIL_REQUEST, requestAccountUnlockEmail);
}

export function* unlockAccountLink() {
  yield takeEvery(UNLOCK_ACCOUNT, unlockAccount);
}

export function* setNewUserPasswordSagaLink() {
  yield takeEvery(NEW_USER_SET_PASSWORD_SAGA, setNewUserPasswordSaga);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(signOutUser),
    fork(requestPasswordResetEmailLink),
    fork(checkIfTokenActive),
    fork(resetPasswordLink),
    fork(requestUnlockAccountEmailLink),
    fork(unlockAccountLink),
    fork(setNewUserPasswordSagaLink),
  ]);
}
