import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
    GET_COMPANY_LIST_SAGA
} from 'constants/ActionTypes';
import {
    errorMessage,
    updateCompanyInStore
} from 'actions';
import { LFAPI } from 'util/LFAPI';


export function* getAllCompanySagaLink() {
    yield takeEvery(GET_COMPANY_LIST_SAGA, getAllCompany);
}

function* getAllCompany() {
    try {
        const response = yield LFAPI.get(`/companies/all`);
        let listData = response && response.data && response.data.data && response.data.data.companies;
        yield put(updateCompanyInStore(listData));
    } catch (err) {
        yield put(updateCompanyInStore([]));
        yield put(errorMessage('There was an error retrieving users. Please try again.', 'Error'));
    }
}

export default function* rootSaga() {
    yield all([fork(getAllCompanySagaLink)]);
}