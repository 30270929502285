import {
  GET_SYNC_ERROR_LOGS,
  GET_SYNC_ERROR_LOGS_SAGA
} from 'constants/ActionTypes';

export const getSyncErrorLogs = data => {
  return {
    type: GET_SYNC_ERROR_LOGS_SAGA,
    payload: data,
  };
};

export const updateSyncErrorLogsInStore = data => {
  return {
    type: GET_SYNC_ERROR_LOGS,
    payload: data,
  };
};