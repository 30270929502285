import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
    GET_ORDER_LIST_SAGA,
    GET_ORDER_SAGA
} from 'constants/ActionTypes';
import {
    successMessage,
    errorMessage,
    updateAllOrdersInStore,
    updateOrderInStore
} from 'actions';
import { LFAPI } from 'util/LFAPI';

function* getAllOrders(action) {
    let { payload } = action
    let queryString = (payload.sort_direction ? `sort_direction=${payload.sort_direction}` : '') +
        (payload.sort ? `&sort=${payload.sort}` : '') +
        (payload.limit ? `&limit=${payload.limit}` : '') +
        (payload.offset ? `&offset=${payload.offset}` : '&offset=0') +
        (payload.search ? `&filter=${payload.search}` : '') +
        (payload.selectedStatus ? `&quick_filter=${payload.selectedStatus}` : '') +
        (payload.organization_uuid ? `&organization_uuid=${payload.organization_uuid}` : '') +
        (payload.from ? `&from=${payload.from}` : '') +
        (payload.to ? `&to=${payload.to}` : '') +
        (payload.organization ? `&organization_uuid=${payload.organization}` : '');

    try {
        const response = yield LFAPI.get(`/purchaseorders/all?${queryString}`);
        let purchaseOrders = response && response.data && response.data.data && response.data

        yield put(updateAllOrdersInStore(purchaseOrders));
    } catch (err) {
        yield put(updateAllOrdersInStore([]));
        yield put(errorMessage('There was an error retrieving users. Please try again.', 'Error'));
    }
}

function* getOrder(action) {
    let { payload } = action
    try {
        const response = yield LFAPI.get(`/purchaseorders/purchaseorder?uuid=${payload}`);
        let purchaseOrder = response && response.data && response.data.data && response.data.data.purchaseOrder
        let result = (purchaseOrder.length) ? purchaseOrder[0] : {}
        yield put(updateOrderInStore(result));

    } catch (err) {
        yield put(updateOrderInStore({}));
        yield put(errorMessage('There was an error retrieving users. Please try again.', 'Error'));
    }
}



export function* getAllOrdersSagaLink() {
    yield takeEvery(GET_ORDER_LIST_SAGA, getAllOrders);
}


export function* getOrderSagaLink() {
    yield takeEvery(GET_ORDER_SAGA, getOrder);
}

export default function* rootSaga() {
    yield all([
        fork(getAllOrdersSagaLink),
        fork(getOrderSagaLink),
    ]);
}