import {
  GET_QBO_ACCOUNT_RECEIVABLES,
  GET_QBO_STATUS,
  GET_QBO_CUSTOMERS,
  GET_QBO_ALL_INVOICES,
  GET_SUPPLIER_ACCOUNTS,
  GET_QBO_INCOME,
  GET_QBO_CLEAR,
  GET_SYNC_INVOICES_TO_QBO_STATUS,
  GET_QBO_TAX_LIST,
  PAYMENT_SYNC_STATUS,
  INVOICE_SYNC_STATUS
} from 'constants/ActionTypes';

const initialQbo = {
  allCustomers: [],
  allQBOInvoices: [],
  allIncomeAccounts: [],
  allClearAccounts: [],
  incomeAccount: '',
  clearAccount: '',
  receivableAccount: '',
  allReceivableAccounts: [],
  status: {},
  syncInvoiceQBOStatus: {},
  qboTaxList: [],
  paymentSyncStatus: false,
  invoiceSyncStatus: false,
};

const Qbo = (state = initialQbo, action) => {
  switch (action.type) {
    case GET_QBO_STATUS:
      return {
        ...state,
        status: action.payload
      }
    case GET_QBO_CUSTOMERS:
      return {
        ...state,
        allCustomers: action.payload
      }

    case GET_QBO_ACCOUNT_RECEIVABLES:
      return {
        ...state,
        allReceivableAccounts: action.payload,
        receivableAccount: action.payload.find(n => n.selected) && action.payload.find(n => n.selected).value
      }

    case GET_QBO_INCOME:
      return {
        ...state,
        allIncomeAccounts: action.payload,
        incomeAccount: action.payload.find(n => n.selected) && action.payload.find(n => n.selected).value
      }

    case GET_QBO_CLEAR:
      return {
        ...state,
        allClearAccounts: action.payload,
        clearAccount: action.payload.find(n => n.selected) && action.payload.find(n => n.selected).value
      }

    case GET_QBO_ALL_INVOICES:
      return {
        ...state,
        allQBOInvoices: action.payload
      }

    case GET_SYNC_INVOICES_TO_QBO_STATUS:
      return {
        ...state,
        syncInvoiceQBOStatus: action.payload

      }
    case GET_QBO_TAX_LIST:
      return {
        ...state,
        qboTaxList: action.payload
      }
    case PAYMENT_SYNC_STATUS:
      return {
        ...state,
        paymentSyncStatus: action.payload
      }
    case INVOICE_SYNC_STATUS:
      return {
        ...state,
        invoiceSyncStatus: action.payload
      }
    default:
      return state;
  }
};

export default Qbo;
