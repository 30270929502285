import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class LFConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby={this.props.label || 'confirmation-box'}
          aria-describedby={this.props.description || 'confirmation-alert'}
        >
          <DialogTitle id="alert-dialog-title">{this.props.title || ''}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.content || ''}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleCancel} color="primary">
              {this.props.cancelText || ''}
            </Button>
            <Button onClick={this.props.handleAccept} color="primary">
              {this.props.acceptText || ''}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default LFConfirm;
