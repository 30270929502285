import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
    GET_ALL_CHART_OF_ACCOUNTS
} from 'constants/ActionTypes';
import { LFAPI } from 'util/LFAPI';
import {
    errorMessage,
    getAllChartOfAccounts,
    updateAllChartOfAccountsInStore,
    makeLoaderActive
} from 'actions';

function* getChartOfAccountList() {
    try {
        yield put(makeLoaderActive(true));
        const response = yield LFAPI.get(`/chartofaccounts/all`);
        let chartOfAccountList = response && response.data && response.data.data.chartOfAccounts;
        yield put(updateAllChartOfAccountsInStore(chartOfAccountList));
    } catch (err) {
        yield put(updateAllChartOfAccountsInStore([]));
        yield put(errorMessage('There was an error retrieving chart of accounts. Please try again.', 'Error'));
    }
}

export function* watchGetChartOfAccountListSagaLink() {
    yield takeEvery(GET_ALL_CHART_OF_ACCOUNTS, getChartOfAccountList);
}


export default function* rootSaga() {
    yield all([
        fork(watchGetChartOfAccountListSagaLink),
    ]);
}