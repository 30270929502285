import { GET_ORDER_LIST, GET_ORDER, GET_COMPANY_LIST } from 'constants/ActionTypes';

const initialUsers = {
  allOrders: [],
  recordCount: 0,
  order: {},
  companyList: []
};

const orders = (state = initialUsers, action) => {
  switch (action.type) {
    case GET_ORDER_LIST:
      let allOrders = action.payload.data.purchaseOrders;
      let count = action.payload.count;
      return {
        ...state,
        allOrders: allOrders,
        recordCount: count,
      };
    case GET_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case GET_COMPANY_LIST:

      return {
        ...state,
        companyList: action.payload,
      };
    default:
      return state;
  }
};

export default orders;
