// users
export const UPDATE_USERS = 'update_users';
export const UPDATE_USERS_SAGA = 'update_users_saga';
export const UPDATE_USER = 'update_user';
export const UPDATE_USER_SAGA = 'update_user_saga';
export const DELETE_USER_SAGA = 'delete_user_saga';
export const DELETE_USER_FROM_STORE = 'delete_user_from_store';
export const DELETE_USER = 'delete_user';
export const GET_ALL_USERS = 'get_all_users';
export const GET_ALL_USERS_SAGA = 'get_all_users_saga';
export const UPDATE_ALL_USERS_IN_STORE = 'update_all_users_in_store';
export const UPDATE_USER_ROLE_SAGA = 'update_user_role_saga';
export const NEW_USER_SAGA = 'new_user_saga';
export const NEW_USER_SET_PASSWORD_SAGA = 'new_user_set_password_saga';

// catalog
export const GET_CATALOG_ITEMS = 'get_catalog_items';
export const ADD_CATALOG_ITEMS = 'add_catalog_items';
export const UPDATE_CATALOG_ITEM = 'update_catalog_item';
export const ADD_NEW_ITEM = 'add_new_item';
export const DELETE_ITEM = 'delete_item';
export const UPLOAD_CATALOG = 'upload_catalog';
export const DOWNLOAD_CATALOG = 'download_catalog';
export const GET_CATALOG_HISTORIES = 'get_catalog_histories';
export const ADD_CATALOG_HISTORIES = 'add_catalog_histories';

// accounts
export const ADD_ALL_ACCOUNTS = 'add_all_accounts';
export const GET_ALL_ACCOUNTS = 'get_all_accounts';
export const GET_ACCOUNT = 'get_account';
export const UPDATE_ACCOUNT_SAGA = 'update_account_saga';
export const ADD_ACCOUNT = 'add_account';
export const TEST_URLS = 'test_urls';

// suppliers
export const ADD_AUTH_SUPPLIER = 'add_auth_supplier';
export const GET_AUTH_SUPPLIER_SAGA = 'get_auth_supplier_saga';
export const UPDATE_AUTH_SUPPLIER = 'update_auth_supplier';
export const UPDATE_AUTH_SUPPLIER_SAGA = 'update_auth_supplier_saga';
export const GET_ALL_SUPPLIER_SAGAS = 'get_all_suppliers_saga';
export const ADD_ALL_SUPPLIERS = 'add_all_suppliers';
export const CREATE_SUPPLIER = 'create_supplier';

//orders
export const GET_ORDER_LIST = 'get_order_list';
export const GET_ORDER_LIST_SAGA = 'get_order_list_saga';
export const GET_ORDER = 'get_order';
export const GET_ORDER_SAGA = 'get_order_saga';

// notifications
export const SHOW_SUCCESS_MESSAGE = 'show_success_message';
export const SHOW_ERROR_MESSAGE = 'show_error_message';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

// Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const RESET_PASSWORD = 'reset_password';
export const RESET_PASSWORD_EMAIL_REQUEST = 'reset_password_EMAIL_REQUEST';
export const UNLOCK_ACCOUNT_EMAIL_REQUEST = 'unlcok_account_email_request';
export const INIT_URL = 'init_url';
export const CHECK_ACTIVE_USER = 'check_active_user';
export const UNLOCK_ACCOUNT = 'unlock_account';
export const REJECT_UNLOCK_TOKEN = 'reject_unlock_token';
export const UPDATE_AUTH_USER = 'update_auth_user';
export const RESET_PASSWORD_SAGA = 'reset_password_saga';

// General const
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';

// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//invoices
export const GET_INVOICE = 'get_invoice';
export const GET_INVOICE_LIST = 'get_invoice_list';
export const GET_PURCHASE_ORDER_FOR_INVOICE = 'get_purchase_order_for_invoice';
export const CLEAR_INVOICE = 'clear_invoice';
export const CLEAR_INVOICE_LIST = 'clear_invoice_list';
export const CREATE_INVOICE = 'create_invoice';
export const CREATE_INVOICE_SAGA = 'create_invoice_saga';
export const GET_INVOICE_SAGA = 'get_invoice_saga';
export const UPDATE_INVOICE_SAGA = 'update_invoice_daga';
export const GET_INVOICE_LIST_SAGA = 'get_invoice_list_saga';
export const GET_PURCHASE_ORDER_FOR_INVOICE_SAGA = 'get_purchase_order_for_invoice_saga';
export const DELETE_INVOICE_SAGA = 'delete_invoice_saga'
export const GET_MORE_INVOICE_LIST = 'get_more_invoice_list';
export const ADD_SINGLE_INVOICE = 'add_single_invoice';
export const GET_ORGANIZATION_LIST = 'get_organization_list';
export const GET_ORGANIZATION_LIST_SAGA = 'get_organization_list_saga';
export const UPDATE_INVOICE_CHECKBOX_SAGE = 'update_invoice_checkbox_sage';

//Companies
export const GET_COMPANY_LIST = 'get_company_list';
export const GET_COMPANY_LIST_SAGA = 'get_company_list_saga';

//QBO
export const GET_SUPPLIER_ACCOUNTS_SAGA = 'get_supplier_accounts_saga'
export const GET_QBO_CUSTOMERS = 'get_qbo_customers'
export const GET_ACCOUNT_RECEIVIBALE_SAGA = 'get_account_receiviable_saga'
export const GET_QBO_STATUS_SAGA = 'get_qbo_status_saga'
export const GET_QBO_CUSTOMERS_SAGA = 'get_qbo_customers_saga'
export const GET_QBO_STATUS = 'get_qbo_status'
export const SET_SUPPLIER_ACCOUNTS = 'set_supplier_accounts'
export const SET_ACCOUNT_RECEIVIABLE = 'set_account_receivable'
export const DELETE_QBO_TOKEN_SAGA = 'delete_qbo_token_saga'
export const UPSERT_QBO_CUSTOMER_SAGA = 'upsert_qbo_customer'
export const GET_QBO_INCOME = 'get_qbo_income'
export const GET_QBO_CLEAR = 'get_qbo_clear'
export const GET_QBO_ACCOUNT_RECEIVABLES = 'GET_QBO_ACCOUNT_RECEIVABLES'
export const UPSERT_QBO_INCOME_ACCOUNT_SAGA = 'upsert_qbo_income_account_saga'
export const UPSERT_QBO_CLEARING_ACCOUNT_SAGA = 'upsert_qbo_clearing_account_saga'
export const UPSERT_QBO_INCOME_ACCOUNT = 'upsert_qbo_income_account'
export const UPSERT_QBO_ACCOUNT_RECEIVABLE_SAGA = 'upsert_qbo_account-receivable_saga'
export const GET_QBO_ALL_INVOICES_SAGA = 'get_qbo_all_invoices_saga'
export const GET_QBO_ALL_INVOICES = 'get_qbo_all_invoices'
export const SYNC_INVOICES_TO_QBO = 'sync_invoices_to_qbo'
export const GET_SYNC_INVOICES_TO_QBO_STATUS = 'get_sync_invoices_to_qbo_status'
export const GET_QBO_TAX_SAGA = 'get_qbo_tax_saga'
export const GET_QBO_TAX_LIST = 'get_qbo_tax_list'
export const UPSERT_QBO_TAX_SAGA = 'upsert_qbo_tax_saga'
export const START_INVOICES_SYNC = 'START_INVOICES_SYNC'
export const START_PAYMENT_SYNC = 'START_PAYMENT_SYNC'
export const PAYMENT_SYNC_STATUS = 'PAYMENT_SYNC_STATUS'
export const INVOICE_SYNC_STATUS = 'INVOICE_SYNC_STATUS'

//Payments
export const GET_PAYMENT_PROFILE = 'get_payment_profile';
export const GET_PAYMENT_PROFILE_SAGA = 'get_payment_profile_saga';
export const UPDATE_PAYMENT_PROFILE_SAGA = 'update_payment_profile_saga';
export const UPDATE_PAYMENT_PROFILE = 'update_payment_profile';
export const GET_PAYMENT_HISTORY = 'get_payment_history';
export const GET_PAYMENT_HISTORY_SAGA = 'get_payment_history_saga';
export const UPDATE_PAYMENT_DETAIL = 'update_payment_detail';


//TaxRegime
export const GET_TAX_LIST_SAGA = 'get_tax_list_saga';
export const GET_TAX_LIST = 'get_tax_list';
export const GET_TAX_AGENCIES = 'get_tax_agency_list';
export const CREAT_TAX_AGENCY = 'add_tax_agency';

//SyncErrorLogs
export const GET_SYNC_ERROR_LOGS = 'get_sync_error_logs';
export const GET_SYNC_ERROR_LOGS_SAGA = 'get_sync_error_logs_saga';

//ChartOfAccounts
export const GET_ALL_CHART_OF_ACCOUNTS = 'get_all_chart_of_accounts';
export const GET_CHART_OF_ACCOUNT_LIST = 'get_chart_of_accounts_list';
export const MAKE_LOADER_ACTIVE = 'make_loader_active';
