import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from 'components/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import { resetPasswordEmailRequest } from 'actions/Auth';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const initialState = {
  email: '',
  hiddenEmail: '',
  validEmail: true,
  resetSubmitted: false
};
class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = initialState;
    this.submitPasswordReset = this.submitPasswordReset.bind(this);
  }

  componentDidUpdate() {}

  submitPasswordReset() {
    const { email } = this.state;
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
      // censor the middle characters of the email address with *
      let hiddenEmail = email.split('@');
      const censorRegex = /(?<!^).(?!$)/g;
      hiddenEmail[0] = hiddenEmail[0].replace(censorRegex, '*');
      hiddenEmail = hiddenEmail.join('@');
      this.setState({ hiddenEmail, validEmail: true, resetSubmitted: true });
      this.props.resetPasswordEmailRequest(email);
    } else {
      NotificationManager.error(<IntlMessages id="signIn.invalidEmail" />);
      this.setState({ validEmail: false });
    }
  }

  render() {
    const { email } = this.state;
    return (
      <div className="app-login-container d-flex flex-column justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="d-flex text-center align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="LabFellows">
            <img
              style={{ maxWidth: '40%', marginBottom: '2rem' }}
              src={require('assets/images/LF-logo-name-horizontal-black.svg')}
              alt="LabFellows"
              title="LabFellows"
            />
          </Link>
        </div>

        <div className="app-login-main-content app-forgot-password-main-content">
          {/* submit email screen */}
          <div className={`app-login-content ${this.state.resetSubmitted ? ' d-none' : ''}`}>
            <div className="app-login-header mb-4">
              <h1 className="forgotPassword extra-bold text-28">
                <IntlMessages id="forgotPassword.forgotYourPassword" />
              </h1>
              <span className="text-16">
                <IntlMessages id="forgotPassword.description" />
              </span>
            </div>

            <div className="app-login-form">
              <form
                onSubmit={e => {
                  e.preventDefault();
                  this.submitPasswordReset();
                }}
              >
                <fieldset>
                  <TextField
                    type="email"
                    label={<IntlMessages id="appModule.email" />}
                    onChange={event => this.setState({ email: event.target.value })}
                    defaultValue={email}
                    error={!this.state.validEmail}
                  />
                  <div className="mt-4">
                    <Button
                      className="btn-full-width"
                      onClick={() => {
                        this.submitPasswordReset();
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="appModule.resetPassword" />
                    </Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
          {/* end submit email screen */}
          {/* reset success screen */}
          <div className={`app-login-content ${this.state.resetSubmitted ? ' ' : ' d-none'}`}>
            <div className="d-flex flex-column align-items-center">
              <div>
                <i className="fas fa-check-circle color-green text-50 mb-4" />
              </div>
              <div className="app-login-header mb-4">
                <h1 className="forgotPassword extra-bold text-28">
                  <IntlMessages id="forgotPassword.resetEmailSent" />
                </h1>
                <span className="text-16">
                  <IntlMessages id="forgotPassword.resetEmailSentDescription" />
                  {this.state.hiddenEmail}
                  <IntlMessages id="forgotPassword.resetEmailSentDescription2" />
                </span>
              </div>
            </div>
            <Button
              className="btn-full-width"
              onClick={() => {
                this.setState({ ...initialState });
                this.props.history.push('/signin');
              }}
              variant="contained"
              color="primary"
            >
              <IntlMessages id="forgotPassword.done" />
            </Button>
          </div>
          {/* end reset success screen */}
        </div>
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(
  mapStateToProps,
  {
    resetPasswordEmailRequest
  }
)(ForgotPassword);
