import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from 'constants/ActionTypes';
import { toggleCollapsedNav, updateWindowWidth } from 'actions/Setting';
import SidenavContent from './SidenavContent';

class SideNav extends React.PureComponent {
  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth);
    });
  }

  onToggleCollapsedNav = e => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  render() {
    const { navCollapsed, drawerType, width, navigationStyle } = this.props;
    // eslint-disable-next-line no-nested-ternary
    let drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'd-xl-flex'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? ''
      : 'd-flex';
    let type = 'permanent';
    if (
      drawerType.includes(COLLAPSED_DRAWER) ||
      (drawerType.includes(FIXED_DRAWER) && width < 1200)
    ) {
      type = 'temporary';
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
      drawerStyle = '';
      type = 'temporary';
    }
    return (
      <div className={`app-sidebar d-none ${drawerStyle}`}>
        <Drawer
          className="app-sidebar-content"
          variant={type}
          open={type.includes('temporary') ? navCollapsed : true}
          onClose={this.onToggleCollapsedNav}
          classes={{
            paper: 'side-nav',
          }}
        >
          {/* todo - put logo here */}
          <div className="sidebar-logo-container">
            {this.props.authUser &&
            this.props.authUser.Supplier &&
            this.props.authUser.Supplier.config &&
            this.props.authUser.Supplier.config.logo ? (
              <img
                className="sideNavLogo"
                src={this.props.authUser.Supplier.config.logo}
                alt="company logo"
              />
            ) : (
              ''
            )}
          </div>
          <SidenavContent history={this.props.history || {}} />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { navCollapsed, drawerType, width, navigationStyle } = settings;
  const { authUser } = auth;
  return { navCollapsed, drawerType, width, navigationStyle, authUser };
};

export default withRouter(
  connect(
    mapStateToProps,
    { toggleCollapsedNav, updateWindowWidth },
  )(SideNav),
);
