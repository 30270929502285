import {
  UPDATE_USERS_SAGA,
  UPDATE_USER_SAGA,
  DELETE_USER_SAGA,
  DELETE_USER_FROM_STORE,
  GET_ALL_USERS_SAGA,
  UPDATE_ALL_USERS_IN_STORE,
  RESET_PASSWORD_SAGA,
  UPDATE_USER_ROLE_SAGA,
  NEW_USER_SAGA,
} from 'constants/ActionTypes';

export const resetPasswordWhileLoggedIn = ({
  currentPassword,
  newPassword,
  confirmNewPassword,
  email,
}) => {
  return {
    type: RESET_PASSWORD_SAGA,
    payload: { currentPassword, newPassword, confirmNewPassword, email },
  };
};

export const updateUser = ({ updates, uuid, self }) => {
  return {
    type: UPDATE_USER_SAGA,
    payload: { updates, uuid, self },
  };
};

export const addNewUser = ({ firstName, lastName, role, email, avatar }) => {
  return {
    type: NEW_USER_SAGA,
    payload: { firstName, lastName, role, email, avatar },
  };
};

export const updateRole = ({ role, uuid }) => {
  return {
    type: UPDATE_USER_ROLE_SAGA,
    payload: { role, uuid },
  };
};

export const updateUsers = users => {
  return {
    type: UPDATE_USERS_SAGA,
    payload: users,
  };
};

export const getAllUsers = () => {
  return {
    type: GET_ALL_USERS_SAGA,
    payload: {},
  };
};

export const updateAllUsersInStore = ({ allUsers }) => {
  return {
    type: UPDATE_ALL_USERS_IN_STORE,
    payload: { allUsers },
  };
};

export const deleteUser = userId => {
  return {
    type: DELETE_USER_SAGA,
    payload: userId,
  };
};

export const deleteUserFromStore = uuid => {
  return {
    type: DELETE_USER_FROM_STORE,
    payload: { uuid },
  };
};
