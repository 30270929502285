import {
  GET_INVOICE_LIST,
  GET_INVOICE_LIST_SAGA,
  GET_INVOICE,
  GET_INVOICE_SAGA,
  UPDATE_INVOICE_SAGA,
  CREATE_INVOICE_SAGA,
  CLEAR_INVOICE,
  CLEAR_INVOICE_LIST,
  GET_PURCHASE_ORDER_FOR_INVOICE_SAGA,
  GET_MORE_INVOICE_LIST,
  ADD_SINGLE_INVOICE,
  GET_ORGANIZATION_LIST,
  GET_ORGANIZATION_LIST_SAGA,
  UPDATE_INVOICE_CHECKBOX_SAGE
} from 'constants/ActionTypes';

export const getAllInvoice = data => {
  return {
    type: GET_INVOICE_LIST_SAGA,
    payload: data,
  };
};
export const updateInvioceCheckbox = data => {
  return {
    type: UPDATE_INVOICE_CHECKBOX_SAGE,
    payload: data,
  };
};

export const getPurchaseOrderForInvoice = data => {
  return {
    type: GET_PURCHASE_ORDER_FOR_INVOICE_SAGA,
    payload: data,
  }
}

export const updateAllInvoiceInStore = allInvoice => {
  return {
    type: GET_INVOICE_LIST,
    payload: allInvoice,
  };
};

export const updateMoreInvoiceInStore = allInvoice => {
  return {
    type: GET_MORE_INVOICE_LIST,
    payload: allInvoice,
  };
};

export const getInvoice = data => {
  return {
    type: GET_INVOICE_SAGA,
    payload: data,
  };
};
export const updateInvoice = data => {
  return {
    type: UPDATE_INVOICE_SAGA,
    payload: data,
  }
}

export const createInvoice = data => {
  return {
    type: CREATE_INVOICE_SAGA,
    payload: data,
  };
};

export const clearInvoice = () => {
  return {
    type: CLEAR_INVOICE,
    payload: {},
  };
};

export const clearInvoiceList = () => {
  return {
    type: CLEAR_INVOICE_LIST,
    payload: {},
  };
};

export const updateInvoiceInStore = invoice => {
  return {
    type: GET_INVOICE,
    payload: invoice,
  };
};

export const addSingleInvoice = invoice => {
  return {
    type: ADD_SINGLE_INVOICE,
    payload: invoice,
  };
};

export const getOrgList = () => {
  return {
    type: GET_ORGANIZATION_LIST_SAGA,
    payload: {},
  };
};

export const updateOrganizationsInStore = data => {
  return {
    type: GET_ORGANIZATION_LIST,
    payload: data,
  };
};