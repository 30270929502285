import {
  GET_AUTH_SUPPLIER_SAGA,
  ADD_AUTH_SUPPLIER,
  UPDATE_AUTH_SUPPLIER,
  UPDATE_AUTH_SUPPLIER_SAGA,
  ADD_ALL_SUPPLIERS,
  GET_ALL_SUPPLIER_SAGAS,
  CREATE_SUPPLIER,
} from 'constants/ActionTypes';

export const createSupplier = name => {
  return {
    type: CREATE_SUPPLIER,
    payload: { name },
  };
};

export const getAllSuppliers = () => {
  return {
    type: GET_ALL_SUPPLIER_SAGAS,
  };
};

export const addAllSuppliers = suppliers => {
  return {
    type: ADD_ALL_SUPPLIERS,
    payload: { suppliers },
  };
};

export const getAuthSupplier = () => {
  return {
    type: GET_AUTH_SUPPLIER_SAGA,
  };
};

export const addAuthSupplier = ({ authSupplier }) => {
  return {
    type: ADD_AUTH_SUPPLIER,
    payload: { authSupplier },
  };
};

export const updateAuthSupplier = ({ updates }) => {
  return {
    type: UPDATE_AUTH_SUPPLIER,
    payload: { updates },
  };
};

export const updateAuthSupplierSaga = ({ updates }) => {
  return {
    type: UPDATE_AUTH_SUPPLIER_SAGA,
    payload: { updates },
  };
};
