/* @flow */

import * as React from 'react';
import MuiTypography from 'material-ui/Typography';
import classnames from 'classnames';

import { withStyles } from 'material-ui/styles';

import type { MuiTheme } from 'config/theme';

type Props = {
  sectionTitle: string,
  classes: Styles
};

const styles = (theme: MuiTheme) => ({
  root: {
    color: '#404352'
  },
  paperTitle: {
    fontSize: '16px',
    fontWeight: '600',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    }
  },
  paperDescription: {
    marginTop: '0.5rem',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      marginTop: 0
    }
  }
});

const Typography = ({ 
  children, 
  sectionTitle,
  paperTitle,
  paperDescription,
  classes,
  ...rest }: Props) => 
  <MuiTypography 
   classes={{
     root: classnames(classes.root, paperTitle && classes.paperTitle, paperDescription && classes.paperDescription)
   }}
  {...rest}>
    {children}
  </MuiTypography>
;

export default withStyles(styles)(Typography);