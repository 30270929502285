import {
    GET_COMPANY_LIST,
    GET_COMPANY_LIST_SAGA
} from 'constants/ActionTypes';

export const getCompanyList = () => {
    return {
        type: GET_COMPANY_LIST_SAGA,
        payload: {},
    };
};

export const updateCompanyInStore = data => {
    return {
        type: GET_COMPANY_LIST,
        payload: data,
    };
};