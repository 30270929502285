import { SHOW_SUCCESS_MESSAGE, SHOW_ERROR_MESSAGE } from 'constants/ActionTypes';

export const successMessage = (body, title) => {
  return {
    type: SHOW_SUCCESS_MESSAGE,
    payload: { body, title }
  };
};

export const errorMessage = (body, title) => {
  return {
    type: SHOW_ERROR_MESSAGE,
    payload: { body, title }
  };
};
