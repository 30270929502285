/* eslint-disable require-yield */
/* eslint-disable no-return-await */
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  UPDATE_USER_SAGA,
  DELETE_USER_SAGA,
  GET_ALL_USERS_SAGA,
  RESET_PASSWORD_SAGA,
  UPDATE_USER_ROLE_SAGA,
  NEW_USER_SAGA,
} from 'constants/ActionTypes';
import {
  deleteUserFromStore,
  successMessage,
  errorMessage,
  updateAllUsersInStore,
  updateAuthUser,
  userSignOutSuccess,
} from 'actions';
import { LFAPI, refreshAuthToken } from 'util/LFAPI';
import translate from 'util/translator';

function* updateUserSaga(action) {
  try {
    const { updates, uuid, self } = action.payload;
    yield LFAPI.patch('/users/user', {
      updates,
      uuid,
    });
    const messageContent = yield translate('users.userUpdateSuccess');
    yield put(successMessage(messageContent, ''));
    yield refreshAuthToken();
    if (self) {
      yield put(updateAuthUser({ updates }));
    }
    if (updates.supplierUuid) {
      setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        localStorage.setItem('refreshNeeded', 'false')
        localStorage.setItem('refreshNeeded', 'true')
        window.location.reload(true)

      }, 100);
    }
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf('Cannot create property \'headers\' on boolean \'false\'') > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(errorMessage('There was an error updating the user. Please try again.', 'Error'));
    }
  }
}

function* newUserSaga(action) {
  try {
    const { firstName, lastName, role, email, avatar } = action.payload;
    yield LFAPI.post('/users/new', {
      firstName,
      lastName,
      role,
      email,
      avatar,
    });
    const messageContent = yield translate('users.newUserSuccess');
    yield put(successMessage(messageContent, ''));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf('Cannot create property \'headers\' on boolean \'false\'') > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else if (err.message.indexOf('444') > -1) {
      yield put(
        errorMessage(
          'This email address is already in use. Please try again with a new email address.',
          'Error',
        ),
      );
    } else {
      yield put(errorMessage('There was an error adding the new user. Please try again.', 'Error'));
    }
  }
}

function* updateUserRoleSaga(action) {
  try {
    const { role, uuid } = action.payload;
    yield LFAPI.patch('/users/role', {
      role,
      uuid,
    });
    const messageContent = yield translate('users.userUpdateSuccess');
    yield put(successMessage(messageContent, ''));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf('Cannot create property \'headers\' on boolean \'false\'') > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(
        errorMessage('There was an error updating the user role. Please try again.', 'Error'),
      );
    }
  }
}

function* resetPasswordLoggedIn(action) {
  try {
    const { currentPassword, newPassword, confirmNewPassword, email } = action.payload;
    yield LFAPI.patch('/users/resetPassword', {
      currentPassword,
      newPassword,
      confirmNewPassword,
      email,
    });
    const messageContent = yield translate('users.passwordUpdateSuccess');
    yield put(successMessage(messageContent, ''));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf('Cannot create property \'headers\' on boolean \'false\'') > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(
        errorMessage('There was an error resetting the password. Please try again.', 'Error'),
      );
    }
  }
}

function* getAllUsers(action) {
  try {
    const response = yield LFAPI.get('/users/all');
    const allUsers = response.data.data.users;
    yield put(updateAllUsersInStore({ allUsers }));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf('Cannot create property \'headers\' on boolean \'false\'') > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(errorMessage('There was an error retrieving users. Please try again.', 'Error'));
    }
  }
}

function* deleteUserSaga(action) {
  try {
    const userId = action.payload;
    // delete user through api call with action
    // remove user from all users array
    yield LFAPI.post('/users/deleteUser', { uuid: userId });
    yield put(deleteUserFromStore(userId));
    const messageContent = yield translate('users.userDeleted');
    yield put(successMessage(messageContent, ''));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf('Cannot create property \'headers\' on boolean \'false\'') > -1
    ) {
      yield put(userSignOutSuccess());
      const messageContent = yield translate('accounts.signedOut');
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(
        errorMessage('We were unable to delete that user.  Please refresh and try again', 'Error'),
      );
    }
  }
}

export function* updateUserSagaLink() {
  yield takeEvery(UPDATE_USER_SAGA, updateUserSaga);
}
export function* deleteUserSagaLink() {
  yield takeEvery(DELETE_USER_SAGA, deleteUserSaga);
}
export function* getAllUsersLink() {
  yield takeEvery(GET_ALL_USERS_SAGA, getAllUsers);
}
export function* resetPasswordLoggedInLink() {
  yield takeEvery(RESET_PASSWORD_SAGA, resetPasswordLoggedIn);
}
export function* updateUserRoleSagaLink() {
  yield takeEvery(UPDATE_USER_ROLE_SAGA, updateUserRoleSaga);
}
export function* newUserSagaLink() {
  yield takeEvery(NEW_USER_SAGA, newUserSaga);
}

export default function* rootSaga() {
  yield all([
    fork(deleteUserSagaLink),
    fork(updateUserSagaLink),
    fork(getAllUsersLink),
    fork(resetPasswordLoggedInLink),
    fork(updateUserRoleSagaLink),
    fork(newUserSagaLink),
  ]);
}
