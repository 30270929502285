import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';
import { checkIfSuperAdmin, checkIfAdmin } from 'util/privileges';

class SidenavContent extends Component {
  // componentDidMount() {
  //   const { history } = this.props;
  //   const that = this;
  //   let pathname;
  //   if (history) {
  //     pathname = `${history.location.pathname}`; // get current path
  //   }
  //   const menuLi = document.getElementsByClassName('menu');
  //   for (let i = 0; i < menuLi.length; i++) {
  //     menuLi[i].onclick = function(event) {
  //       const parentLiEle = that.closest(this, 'li');
  //       if (menuLi[i].classList.contains('menu') && parentLiEle !== null) {
  //         event.stopPropagation();

  //         if (menuLi[i].classList.contains('open')) {
  //           menuLi[i].classList.remove('open', 'active');
  //         } else {
  //           menuLi[i].classList.add('open', 'active');
  //         }
  //       } else {
  //         for (let j = 0; j < menuLi.length; j++) {
  //           const parentLi = that.closest(this, 'li');
  //           if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
  //             menuLi[j].classList.remove('open');
  //           } else if (menuLi[j].classList.contains('open')) {
  //             menuLi[j].classList.remove('open');
  //           } else {
  //             menuLi[j].classList.add('open');
  //           }
  //         }
  //       }
  //     };
  //   }

  //   const activeLi = document.querySelector(`a[href="${pathname}"]`); // select current a element
  //   try {
  //     const activeNav = this.closest(activeLi, 'ul'); // select closest ul
  //     if (activeNav.classList.contains('sub-menu')) {
  //       this.closest(activeNav, 'li').classList.add('open');
  //     } else {
  //       this.closest(activeLi, 'li').classList.add('open');
  //     }
  //   } catch (error) {}
  // }

  // componentWillReceiveProps(nextProps) {
  //   const { history } = nextProps;
  //   let pathname;
  //   if (history) {
  //     pathname = `${history.location.pathname}`; // get current path
  //   }
  //   console.log('pathname', pathname);
  //   console.log('history', history);
  //   const activeLi = document.querySelector(`a[href="${pathname}"]`); // select current a element
  //   try {
  //     const activeNav = this.closest(activeLi, 'ul'); // select closest ul
  //     if (activeNav.classList.contains('sub-menu')) {
  //       this.closest(activeNav, 'li').classList.add('open');
  //     } else {
  //       this.closest(activeLi, 'li').classList.add('open');
  //     }
  //   } catch (error) {}
  // }

  // closest(el, selector) {
  //   try {
  //     let matchesFn;
  //     // find vendor prefix
  //     [
  //       'matches',
  //       'webkitMatchesSelector',
  //       'mozMatchesSelector',
  //       'msMatchesSelector',
  //       'oMatchesSelector',
  //     ].some(function(fn) {
  //       if (typeof document.body[fn] === 'function') {
  //         matchesFn = fn;
  //         return true;
  //       }
  //       return false;
  //     });

  //     let parent;

  //     // traverse parents
  //     while (el) {
  //       parent = el.parentElement;
  //       if (parent && parent[matchesFn](selector)) {
  //         return parent;
  //       }
  //       el = parent;
  //     }
  //   } catch (e) {}

  //   return null;
  // }

  isActive(lookup, string) {
    if (string.indexOf(lookup) > -1) {
      return 'active open';
    }
    return '';
  }

  render() {
    const { history } = this.props;
    let pathname;
    if (history) {
      pathname = `${history.location.pathname}`; // get current path
    }
    // console.log('pathname', pathname);
    // console.log('history', history);
    // console.log('this.props', this.props);
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li className={`menu no-arrow ${this.isActive('analytics', pathname)}`}>
            <NavLink to="/app/analytics">
              <i className="fas fa-chart-line" />
              <span className="nav-text">
                <IntlMessages id="pages.analytics" />
                {' '}
              </span>
            </NavLink>
          </li>
          <li className={`menu no-arrow ${this.isActive('accounts', pathname)}`}>
            <NavLink to="/app/accounts">
              <i className="fas fa-file-invoice" />
              <span className="nav-text">
                <IntlMessages id="pages.accounts" />
                {' '}
              </span>
            </NavLink>
          </li>
          <li className={`menu no-arrow ${this.isActive('users', pathname)}`}>
            <NavLink to="/app/users">
              <i className="fas fa-users" />
              <span className="nav-text">
                <IntlMessages id="pages.users" />
                {' '}
              </span>
            </NavLink>
          </li>
          <li className={`menu no-arrow ${this.isActive('company', pathname)}`}>
            <NavLink to="/app/company">
              <i className="fas fa-cog" />
              <span className="nav-text">
                <IntlMessages id="pages.companySettings" />
                {' '}
              </span>
            </NavLink>
          </li>
          <li className={`menu no-arrow ${this.isActive('ChartOfAccounts', pathname)} ${checkIfAdmin(this.props.authUser) ? '' : 'd-none'}`}>
            <ul className={`sub-menu`}>
              <li className={`no-arrow`}>
                <NavLink to="/app/ChartOfAccounts">
                  <i className="fas fa-user-cog" />
                  <span className="nav-text">
                    <IntlMessages id="pages.accountSettings" />
                    {' '}
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className={`menu no-arrow ${checkIfSuperAdmin(this.props.authUser) ? '' : 'd-none'}`}>
            <NavLink to="/app/newSupplier">
              <i className="fas fa-plus" />
              <span className="nav-text">Add Supplier </span>
            </NavLink>
          </li>
          <li className={`menu no-arrow ${this.isActive('company', pathname)}`}>
            <NavLink to="/app/orders">
              <i className="fas fa-list-alt" />
              <span className="nav-text">
                <IntlMessages id="pages.orders" />
                {' '}
              </span>
            </NavLink>
          </li>
          <li className={`menu no-arrow ${this.isActive('company', pathname)}`}>
            <NavLink to="/app/invoices">
              <i className="fas fa-list-alt" />
              <span className="nav-text">
                <span className="nav-text">Invoices </span>
                {' '}
              </span>
            </NavLink>
          </li>
          <li className={`menu no-arrow ${this.isActive('company', pathname)}`}>
            <NavLink to="/app/payments">
              <i className="fas fa-list-alt" />
              <span className="nav-text">
                <span className="nav-text">Payments </span>
                {' '}
              </span>
            </NavLink>
          </li>
          <li className={`menu no-arrow ${this.isActive('company', pathname)}`}>
            <NavLink to="/app/integrations">
              <i className="fas fa-link" />
              <span className="nav-text">
                <IntlMessages id="pages.integrations" />
                {' '}
              </span>
            </NavLink>
          </li>
        </ul>
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default injectIntl(
  connect(
    mapStateToProps,
    {},
  )(SidenavContent),
);
