/* eslint-disable no-restricted-globals */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { microserviceAPI } from 'util/MicroserviceAPI';
import Button from '@material-ui/core/Button';
import { NotificationContainer } from 'react-notifications';
import { hideMessage, showAuthLoader, userSignIn } from 'actions/Auth';
import paymentsImage from 'assets/images/payments-image.svg';
import paymentsCheckIcon from 'assets/images/payments-check-icon.svg';


class SupplierToS extends React.Component {
  constructor() {
    super();
    this.state = {
      submitted: false
    };

    // this.handleAgree = this.handleAgree.bind(this);
  }

  componentDidMount() {
    fetch('https://api.ipify.org/?format=json')
      .then(response => response.json())
      .then(({ ip }) => {
        return microserviceAPI(`payment_profiles/${this.props.match.params.id}/verify`, {
          ref: 'CHACHING', method: 'POST', body: {
            ip,
            timestamp: Math.floor(Date.now() / 1000),
          }
        });
        // this.setState({ ip });
      })
  }

  // handleAgree = async () => {
  //   try {
  //     await microserviceAPI(`payment_profiles/${this.props.match.params.id}/verify`, {
  //       ref: 'CHACHING', method: 'POST', body: {
  //         ip: this.state.ip,
  //         timestamp: Math.floor(Date.now() / 1000)
  //       }
  //     })
  //     this.setState({ submitted: true });
  //   } catch (err) {

  //   }
  // }

  render() {
    // const { submitted } = this.state;

    return (
      <>
        {
          // submitted ?
          <div className="app-login-container d-flex flex-column justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div style={{ width: 576, height: 500, flexDirection: 'column', alignItems: 'center' }} className="app-login-main-content">
              <img src={paymentsImage} style={{ marginBottom: 40, marginTop: -60 }} />
              <img src={paymentsCheckIcon} style={{ marginBottom: 10 }} />
              <h3>Account Created</h3>
              <span style={{ marginTop: 20, opacity: 0.6 }}>You are all set to receive</span>
              <span style={{ opacity: 0.6 }}>electronic payments from {this.props.match.params.name}!</span>
            </div>
          </div>
          // :
          // <div className="app-login-container d-flex flex-column justify-content-flex-start align-items-flex-start animated slideInUpTiny animation-duration-3">
          //   <div className="d-flex text-flex-start align-items-flex-start justify-content-flex-start">
          //     <Link className="logo-lg" to="/" title="LabFellows">
          //       <img
          //         style={{ maxWidth: '40%', marginBottom: '2rem' }}
          //         src={require('assets/images/LF-logo-name-horizontal-black.svg')}
          //         alt="LabFellows"
          //         title="LabFellows"
          //       />
          //     </Link>
          //   </div>
          //   <div style={{ padding: 25 }}>
          //     <h4 style={{ marginBottom: 40 }}>
          //       Payment Agreement
          //     </h4>
          //     <span>
          //       Payment processing services for suppliers on LabFellows are provided by Stripe and are subject to the <a style={{ color: '#0000EE' }} target="_blank" rel="noopener noreferrer" href="https://stripe.com/connect-account/legal">Stripe Connected Account Agreement</a>,
          //       which includes the Stripe Terms of Service (collectively, the “<a style={{ color: '#0000EE' }} target="_blank" rel="noopener noreferrer" href="https://stripe.com/legal">Stripe Services Agreement</a>”). By agreeing to these terms or continuing to operate as a supplier on LabFellows,
          //       you agree to be bound by the Stripe Services Agreement, as the same may be modified by Stripe from time to time.
          //       As a condition of LabFellows enabling payment processing services through Stripe, you agree to provide LabFellows accurate and complete information about you and your business,
          //       and you authorize LabFellows to share it and transaction information related to your use of the payment processing services provided by Stripe.
          //     </span>
          //   </div>
          //   <div style={{ marginTop: 30 }} className="d-flex text-center align-items-center justify-content-center">
          //     <Button
          //       className="btn mt-2 mb-2 lf-btn-contained"
          //       variant="contained"
          //       color="primary"
          //       onClick={this.handleAgree}
          //     >
          //       I Agree to these Terms of Services
          //     </Button>
          //   </div>
          // </div>
        }
        <NotificationContainer />
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(
  mapStateToProps,
  {
    userSignIn,
    hideMessage,
    showAuthLoader,
  },
)(SupplierToS);
