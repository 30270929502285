/* eslint-disable require-yield */
/* eslint-disable no-return-await */
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  GET_ACCOUNT,
  GET_ALL_ACCOUNTS,
  UPDATE_ACCOUNT_SAGA,
  TEST_URLS,
} from 'constants/ActionTypes';

import {
  updateAuthSupplier,
  successMessage,
  errorMessage,
  userSignOutSuccess,
  addAllAccounts,
  addAccount,
} from 'actions';
import { LFAPI } from 'util/LFAPI';
import translate from 'util/translator';

function* testOrderUrls(action) {
  try {
    const { punchoutTestUrl, punchoutUrl, cxmlOrderUrl, cxmlTestUrl } = action.payload;
    const response = yield LFAPI.post('/accounts/testURLs', {
      punchoutTestUrl,
      punchoutUrl,
      cxmlOrderUrl,
      cxmlTestUrl,
    });
    // const { accounts } = response.data.data;
    // yield put(addAllAccounts({ accounts }));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf('Cannot create property \'headers\' on boolean \'false\'') > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(
        errorMessage('There was an error getting the accounts. Please try again.', 'Error'),
      );
    }
  }
}

function* getAllAccountsSaga() {
  try {
    const response = yield LFAPI.get('/accounts/accounts');
    const { accounts } = response.data.data;
    yield put(addAllAccounts({ accounts }));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf('Cannot create property \'headers\' on boolean \'false\'') > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(
        errorMessage('There was an error getting the accounts. Please try again.', 'Error'),
      );
    }
  }
}

function* getAccountSaga({ payload }) {
  try {
    const { accountUuid } = payload;
    const response = yield LFAPI.post('/accounts/account', { accountUuid });
    const { account } = response.data.data;
    yield put(addAccount({ account }));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf('Cannot create property \'headers\' on boolean \'false\'') > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(
        errorMessage('There was an error getting the accounts. Please try again.', 'Error'),
      );
    }
  }
}

function* updateAccountSaga(action) {
  try {
    const { updates, uuid } = action.payload;
    yield LFAPI.patch('/accounts/account', { updates, uuid });
    const messageContent = yield translate('accounts.successfullyUpdated');
    yield put(successMessage(messageContent));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf('Cannot create property \'headers\' on boolean \'false\'') > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(
        errorMessage('There was an error getting the supplier. Please try again.', 'Error'),
      );
    }
  }
}

export function* updateAccountSagaLink() {
  yield takeEvery(UPDATE_ACCOUNT_SAGA, updateAccountSaga);
}

export function* testOrderUrlsLink() {
  yield takeEvery(TEST_URLS, testOrderUrls);
}

export function* getAllAccountsSagaLink() {
  yield takeEvery(GET_ALL_ACCOUNTS, getAllAccountsSaga);
}
export function* getAccountSagaLink() {
  yield takeEvery(GET_ACCOUNT, getAccountSaga);
}

export default function* rootSaga() {
  yield all([
    fork(testOrderUrlsLink),
    fork(updateAccountSagaLink),
    fork(getAllAccountsSagaLink),
    fork(getAccountSagaLink),
  ]);
}
