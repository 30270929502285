import { ADD_CATALOG_ITEMS, ADD_CATALOG_HISTORIES } from 'constants/ActionTypes';
/* eslint-disable no-case-declarations */
const initialCatalogsMock = [
  {
    accountId: 1,
    catalogItems: [
      {
        id: 112345,
        number: '123123',
        name: 'A Spectacular Scientific Item',
        description:
          'Every lab needs this wonderful chemical in order to do their incredible science experiments',
        uom: 'EA',
        price: '97.75',
      },
      {
        id: 129090,
        number: '129090',
        name: 'The Second Spectacular Scientific Item',
        description: 'Every lab needs this wonderful chemical',
        uom: 'EA',
        price: '97.75',
      },
    ],
    uploadHistory: [
      {
        name: 'catalog_from_20190902.csv',
        created_date: 1569801600000,
        link: 'http://www.google.com',
      },
      {
        name: 'catalog_from_20190902.csv',
        created_date: 1569801600000,
        link: 'http://www.google.com',
      },
    ],
  },
];

const initialCatalogs = {
  allCatalogs: [],
};

const catalogs = (state = initialCatalogs, action) => {
  switch (action.type) {
    case ADD_CATALOG_ITEMS:
      return {
        ...state,
        items: action.payload.items,
      };
    case ADD_CATALOG_HISTORIES:
      return {
        ...state,
        uploadHistory: action.payload.catalogs,
      };
    default:
      return state;
  }
};

export default catalogs;
