import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Settings';
import Auth from './Auth';
import Users from './Users';
import Accounts from './Accounts';
import Catalogs from './Catalogs';
import Suppliers from './Suppliers';
import Orders from './Orders';
import Invoices from './Invoices';
import Qbo from './Qbo'
import payment from './Payments'
import taxRegime from './TaxRegime'
import syncErrorLogs from './SyncErrorLogs'
import chartOfAccounts from './ChartOfAccounts';
export default history =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    users: Users,
    accounts: Accounts,
    catalogs: Catalogs,
    suppliers: Suppliers,
    orders: Orders,
    invoices: Invoices,
    qbo: Qbo,
    payment,
    taxRegime,
    syncErrorLogs,
    chartOfAccounts
  });
