import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import { NotificationContainer } from 'react-notifications';

class RejectInvite extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidUpdate() {}

  render() {
    return (
      <div className="app-login-container d-flex flex-column justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="d-flex text-center align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="LabFellows">
            <img
              style={{ maxWidth: '40%', marginBottom: '2rem' }}
              src={require('assets/images/LF-logo-name-horizontal-black.svg')}
              alt="LabFellows"
              title="LabFellows"
            />
          </Link>
        </div>

        <div className="app-login-main-content app-forgot-password-main-content">
          {/* reset success screen */}
          <div className="app-login-content">
            <div className="d-flex flex-column align-items-center">
              <div className="app-login-header mb-4">
                <h1 className="forgotPassword extra-bold text-28 mb-4">
                  <IntlMessages id="rejectInvite.sorryYouRejected" />
                </h1>
                <span className="mt-4 text-16">
                  <IntlMessages id="rejectInvite.sorryYouRejectedDescription" />
                </span>
              </div>
            </div>
            <a href="https://www.labfellows.com" target="_self">
              <Button className="btn-full-width" variant="contained" color="primary">
                <IntlMessages id="appModule.learnMore" />
              </Button>
            </a>
          </div>
          {/* end reset success screen */}
        </div>
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(RejectInvite);
