/* @flow */
/* eslint-disable import/no-named-default */

import * as React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { withStyles } from 'material-ui/styles';
import { CircularProgress } from 'material-ui/Progress';
import { default as MuiButton } from 'material-ui/Button';
import Icon from 'components/Icon';
import type { MuiTheme } from 'config/theme';

type Props = {
  to?: string,
  loading: boolean,
  children: any,
  classes: Styles,
  iconLeft?: string,
  iconRight?: string,
  iconColor?: string,
  disabled?: boolean,
  whiteLoader: boolean,
};

const styles = (theme: MuiTheme) => ({
  button: {
    margin: theme.spacing.unit,
    position: 'relative',
    color: '#ffffff',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: '#5089CC',
      color: '#ffffff !important',
    },
  },
  flatButton: {
    backgroundColor: '#64A6F4',
    borderRadius: '4px',
    '&:hover': {
      opacity: '1',
      backgroundColor: '#157FF2',
    },
    '& > span': {
      color: '#fff',
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[500],

    },
  },
  flatButtonWhite: {
    backgroundColor: '#ffffff',
    border: '1px solid #64A6F4',
    borderRadius: '4px',
    '&:hover': {
      '& > span': {
        color: '#64A6F4',
      },
      border: '1px solid #157FF2',
      backgroundColor: '#157FF2',
    },
    '& > span': {
      color: '#64A6F4',
    },
    '&:disabled': {
      opacity: '0.5',
      '& > span': {
        color: '#64A6F4',

      },
    },
  },
  flatButtonGreen: {
    backgroundColor: '#77c10b',
    borderRadius: '2px',
    opacity: '0.9',
    '&:hover': {
      opacity: '1',
      backgroundColor: '#77c10b',
    },
    '& > span': {
      color: '#fff',
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[500],
    },
  },
  noBorder: {
    border: 'none',
  },
  noMargin: {
    margin: '0px',
  },
  disabled: {
    color: theme.palette.grey[500],
  },
  iconLeft: {
    marginRight: theme.spacing.unit,
  },
  iconRight: {
    marginLeft: theme.spacing.unit,
  },
  progress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  whiteProgress: {
    color: 'white !important',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  mobileWidth: {
    [theme.breakpoints.down('xs')]: {
      width: '48%',
      margin: '14px 0',
    },
  },
});

const Button = ({
  to,
  loading,
  children,
  classes,
  iconLeft,
  iconRight,
  iconColor,
  disabled,
  flatButton,
  flatButtonWhite,
  flatButtonGreen,
  noBorder,
  noMargin,
  mobileWidth,
  whiteLoader,
  ...rest
}: Props) => {
  const component = to ? Link : 'button';
  return (
    <MuiButton
      component={component}
      to={to}
      style={{ textTransform: 'capitalize' }}
      className={classnames(
        classes.button,
        flatButton && classes.flatButton,
        flatButtonWhite && classes.flatButtonWhite,
        flatButtonGreen && classes.flatButtonGreen,
        noBorder && classes.noBorder,
        noMargin && classes.noMargin,
        mobileWidth && classes.mobileWidth,
      )}
      classes={{
        label: classnames({ [classes.disabled]: disabled }),
      }}
      disabled={disabled}
      {...rest}
    >
      {iconLeft && (
        <Icon className={classes.iconLeft} color={iconColor}>
          {iconLeft}
        </Icon>
      )}
      {children}
      {iconRight && (
        <Icon className={classes.iconRight} color={iconColor}>
          {iconRight}
        </Icon>
      )}
      {loading && (
        <CircularProgress
          size={24}
          className={whiteLoader ? classes.whiteProgress : classes.progress}
        />
      )}
    </MuiButton>
  );
};

Button.defaultProps = {
  to: '',
  iconLeft: '',
  iconRight: '',
  iconColor: 'primary',
  disabled: false,
};

export default withStyles(styles)(Button);
