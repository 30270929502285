import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from 'components/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import { checkPasswords } from 'util/PasswordParser';
import { resetPassword } from 'actions/Auth';
import { NotificationManager } from 'react-notifications';
import queryString from 'query-string';

const initialState = {
  newPassword: '',
  confirmNewPassword: '',
  passwordsValid: true,
};
class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = initialState;
    this.submitNewPassword = this.submitNewPassword.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    // eslint-disable-next-line no-restricted-globals
    const urlParams = queryString.parse(location.search);
    if (!urlParams.t || !urlParams.email) {
      this.props.history.replace('/forgotpassword');
    } else {
      const { t, email } = urlParams;
      this.setState({ t, email });
    }
  }

  submitNewPassword() {
    const { newPassword, confirmNewPassword, t, email } = this.state;
    const { valid, error } = checkPasswords(newPassword, confirmNewPassword);
    if (!valid) {
      const passwordsValid = false;
      NotificationManager.error(<IntlMessages id={error} />);
      this.setState({ passwordsValid });
    } else {
      const passwordsValid = true;
      this.setState({ passwordsValid });
      this.props.resetPassword(email, newPassword, confirmNewPassword, t);
      this.props.history.replace('/signin');
    }
  }

  render() {
    const { showMessage } = this.props;
    return (
      <div className="app-login-container d-flex flex-column justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="d-flex text-center align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="LabFellows">
            <img
              style={{ maxWidth: '40%', marginBottom: '2rem' }}
              src={require('assets/images/LF-logo-name-horizontal-black.svg')}
              alt="LabFellows"
              title="LabFellows"
            />
          </Link>
        </div>

        <div className="app-login-main-content">
          {/* reset password screen */}
          <div className={`app-login-content ${this.state.resetSubmitted ? ' d-none' : ''}`}>
            <div className="app-login-header mb-4">
              <h1 className="forgotPassword extra-bold text-28">
                <IntlMessages id="resetPassword.resetPassword" />
              </h1>
              <span className="text-16">
                <IntlMessages id="resetPassword.resetPasswordDescription" />
              </span>
            </div>

            <div className="app-login-form">
              <form
                onSubmit={e => {
                  e.preventDefault();
                  this.submitNewPassword();
                }}
              >
                <fieldset>
                  <TextField
                    type="password"
                    label={<IntlMessages id="resetPassword.newPassword" />}
                    onChange={event => this.setState({ newPassword: event.target.value })}
                    error={!this.state.passwordsValid}
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="resetPassword.confirmNewPassword" />}
                    onChange={event => this.setState({ confirmNewPassword: event.target.value })}
                    error={!this.state.passwordsValid}
                  />
                  <div className="mt-3 text-16">
                    <IntlMessages id="signUp.passwordRequirement" />
                  </div>
                  <div className="mt-4">
                    <Button
                      className="btn-full-width"
                      onClick={() => {
                        this.submitNewPassword();
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="appModule.resetPassword" />
                    </Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
          {/* end reset password screen */}
          {/* reset success screen */}
          <div className={`app-login-content ${this.state.resetSubmitted ? ' ' : ' d-none'}`}>
            <div className="d-flex flex-column align-items-center">
              <div>
                <i className="fas fa-check-circle color-green text-50 mb-4" />
              </div>
              <div className="app-login-header mb-4">
                <h1 className="forgotPassword extra-bold text-28">
                  <IntlMessages id="resetPassword.passwordResetSuccess" />
                </h1>
                <span className="text-16">
                  <IntlMessages id="resetPassword.passwordResetSuccessDescription" />
                </span>
              </div>
            </div>
            <Button
              className="btn-full-width"
              onClick={() => {
                this.props.showAuthLoader();
                this.setState({ ...initialState });
                this.props.history.push('/signin');
              }}
              variant="contained"
              color="primary"
            >
              <IntlMessages id="appModule.signIn" />
            </Button>
          </div>
          {/* end reset success screen */}
        </div>
        {/* {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )} */}
        {showMessage && this.props.history.push('/signin?invalidToken=true')}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { showMessage, authUser } = auth;
  return { showMessage, authUser };
};

export default connect(
  mapStateToProps,
  {
    resetPassword,
  },
)(ResetPassword);
