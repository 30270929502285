export const checkIfAdmin = user => {
  return (user && user.UserRole && user.UserRole.Role.name === 'ADMIN') || user.superAdmin;
};

export const checkIfSuperAdmin = user => {
  return user && user.superAdmin;
};

export const checkIfAccountOwner = (account, user) => {
  return account.accountAssignee === user.uuid || account.punchoutAssignee === user.uuid;
};

export const setTOS = () => {
  let userDetails = JSON.parse(localStorage.getItem("userDetails"))
  userDetails = {
    ...userDetails,
    isTOSShown: false
  }
  localStorage.setItem("userDetails", JSON.stringify(userDetails))
  window.location.reload(false);
}