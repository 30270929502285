import {
  GET_SYNC_ERROR_LOGS
} from 'constants/ActionTypes';

const initialState = {
  loading: false,
  allSyncErrorLogs: { columns: [], rows: [] }
};

const SyncErrorLogs = (state = initialState, action) => {
  switch (action.type) {
    case GET_SYNC_ERROR_LOGS:
      let allSyncErrorLogs = action.payload;
      return {
        ...state,
        allSyncErrorLogs
      };
    default:
      return state;
  }
};

export default SyncErrorLogs;
