import axios from 'axios';
import { saveAuthToken, getAuthToken, deleteAuthToken } from 'util/LocalStorage';

const baseURL = process.env.REACT_APP_API_URL;
const timeout = 15000;

// instantiate axios as a specific authenticated requestor
const LFAPI = axios.create({
  baseURL,
  timeout,
  headers: {},
});

const LFAPINoAuth = axios.create({
  baseURL,
  timeout,
  headers: {},
});

const refreshTokenWrapper = axios.create({
  baseURL,
  timeout,
  headers: {},
});

let authTokenRequest;

// if the refresh token request responds with new token, then save new token to local storage
function refreshAuthToken() {
  return new Promise(function(resolve, reject) {
    refreshTokenWrapper
      .post('/auth/refresh')
      .then(function(response) {
        if (response) {
          saveAuthToken(response.headers.authorization);
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

function resetAuthTokenRequest() {
  authTokenRequest = null;
}
// This function makes a call to get the auth token
// or it returns the same promise as an in-progress call to get the auth token
function refreshAuthTokenThrottler() {
  if (!authTokenRequest) {
    authTokenRequest = refreshAuthToken();
    authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
  }
  return authTokenRequest;
}

const addAuthHeader = config => {
  // eslint-disable-next-line prefer-const
  let configWithAuth = config;
  const authorization = getAuthToken();
  if (authorization) {
    configWithAuth.headers.authorization = authorization;
    return configWithAuth;
  }
  // if there is no auth token then redirect to login screen and clear all states
  return false;
};

LFAPI.interceptors.request.use(addAuthHeader, function(error) {
  return Promise.reject(error);
});

refreshTokenWrapper.interceptors.request.use(addAuthHeader, function(error) {
  return Promise.reject(error);
});

LFAPI.interceptors.response.use(
  response => {
    return Promise.resolve(response);
  },
  err => {
    // if the response is a 401 then attempt to refresh the token one time
    const error = err.response;
    if (error && error.status === 401) {
      return refreshAuthTokenThrottler()
        .then(() => {
          return LFAPI(error.config);
        })
        .catch(err => {
          // if the refresh token request still responds 401 then redirect to the login screen
          deleteAuthToken();
          return Promise.reject(err);
        });
    }
    return Promise.reject(err);
  },
);

export { LFAPI, LFAPINoAuth, refreshAuthToken };
