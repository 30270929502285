import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_PAYMENT_PROFILE_SAGA, UPDATE_PAYMENT_PROFILE_SAGA, GET_PAYMENT_HISTORY_SAGA, GET_PAYMENT_HISTORY, UPDATE_PAYMENT_DETAIL } from 'constants/ActionTypes';

import { microserviceAPI } from '../util/MicroserviceAPI'

import { getPaymentProfile, updatePaymentProfile, getPaymentHistory, errorMessage, successMessage, userSignOutSuccess } from 'actions';

import translate from 'util/translator';
import { LFAPI } from 'util/LFAPI';
import { setTOS } from 'util/privileges'

function* getPaymentProfileAPI(action) {
  const supplierUUID = action.payload;
  try {
    const endPoint = `payment_profiles?supplier=${supplierUUID}`;
    const res = yield microserviceAPI(endPoint, { ref: "CHACHING" });
    const paymentProfile = res.data.find(each => each.verified) || {};
    yield put(getPaymentProfile(paymentProfile))
  } catch (error) {
    console.log('Error ', error)
    yield put(errorMessage("Unable to retrieve payment profile", "Error"))
  }
}

function* getPaymentHistroyAPI(action) {
  const supplierUUID = action.payload;
  try {
    const endPoint = `supplier_portal/payments/paymenthistory?supplier=${supplierUUID}`;
    let res = yield microserviceAPI(endPoint, { ref: "CHACHING" });
    const invAvl = yield LFAPI.get(`/invoices/all-paid`);
    const avlInvNums = invAvl.data.data.invoices.map(each => each.invoice_number);
    res = res.filter(each => avlInvNums.includes(each.INVOICE_NUMBER));
    console.log(avlInvNums, res);
    yield put(getPaymentHistory(res))
  } catch (error) {
    console.log('Error ', error)
    yield put(errorMessage("Unable to retrieve payment histroy", "Error"))
  }
}

function* updatePaymentProfileAPI(action) {
  try {
    const { updates } = action.payload;
    yield microserviceAPI('payment_profiles', { ref: 'CHACHING', method: 'PUT', body: updates });
    yield put(updatePaymentProfile({ updates }));
    const messageContent = yield translate('payment.successfullyUpdated');
    yield put(successMessage(messageContent));
    yield setTOS()
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf("Cannot create property 'headers' on boolean 'false'") > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(
        errorMessage('There was an error updating the payment information. Please try again.', 'Error'),
      );
    }
  }
  return true
}

function* updatePaymentDetailAPI(action) {
  try {
    const data = action.payload;
    yield microserviceAPI('supplier_portal/payments/update', { ref: 'CHACHING', method: 'PUT', body: data });
    const messageContent = yield translate('payment.successfullyUpdated');
    yield put(successMessage(messageContent));
  } catch (err) {
    if (
      err.message.indexOf('401') > -1 ||
      err.message.indexOf("Cannot create property 'headers' on boolean 'false'") > -1
    ) {
      const messageContent = yield translate('accounts.signedOut');
      yield put(userSignOutSuccess());
      yield put(errorMessage(messageContent, 'Error'));
    } else {
      yield put(
        errorMessage('There was an error updating the payment information. Please try again.', 'Error'),
      );
    }
  }
}

export function* watchGetPaymentProfileAPI() {
  yield takeEvery(GET_PAYMENT_PROFILE_SAGA, getPaymentProfileAPI)
}

export function* watchGetPaymentHistroyAPI() {
  yield takeEvery(GET_PAYMENT_HISTORY_SAGA, getPaymentHistroyAPI)
}


export function* watchUpdatePaymentProfileAPI() {
  yield takeEvery(UPDATE_PAYMENT_PROFILE_SAGA, updatePaymentProfileAPI);
}

export function* watchUpdatePaymentDetailAPI() {
  yield takeEvery(UPDATE_PAYMENT_DETAIL, updatePaymentDetailAPI);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetPaymentProfileAPI),
    fork(watchGetPaymentHistroyAPI),
    fork(watchUpdatePaymentProfileAPI),
    fork(watchUpdatePaymentDetailAPI)
  ]);
}