import {
    GET_ORDER_LIST,
    GET_ORDER_LIST_SAGA,
    GET_ORDER,
    GET_ORDER_SAGA,
} from 'constants/ActionTypes';

export const getAllOrders = (data) => {
    return {
        type: GET_ORDER_LIST_SAGA,
        payload: data,
    };
};


export const updateAllOrdersInStore = (allOrders) => {
    return {
        type: GET_ORDER_LIST,
        payload: allOrders,
    };
};

export const getOrder = (uuid) => {
    return {
        type: GET_ORDER_SAGA,
        payload: uuid,
    };
};


export const updateOrderInStore = (order) => {
    return {
        type: GET_ORDER,
        payload: order,
    };
};