/* All the microservice should use this method to authenticate via doorbell */
import { getAuthToken } from 'util/LocalStorage';

export const microserviceAPI = async (
  path: string, {
    method,
    headers,
    body,
    version = 'v1',
    ref
  }: Options = {}
): Promise<*> => {
  let resource;
  // Base URL
  switch (ref) {
    case "QBO": {
      const authorization = `Bearer ${getAuthToken()}`;
      resource = process.env.REACT_APP_QUICKBOOKS_API_URL + path
      headers = { ...headers, authorization }
      break
    }
    case "CHACHING": {
      const authorization = `Bearer ${getAuthToken()}`;
      resource = process.env.REACT_APP_CHACHING_API_URL + '/' + path;
      headers = { ...headers, authorization };
      break
    }
    default: {
      resource = ""
      break
    }
  }

  // Build options
  const init = {
    method: method || 'GET',
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
      'X-Content-Type-Options': 'nosniff',
      'X-Frame-Options': 'sameorigin',
      'X-XSS-Protection': 1,
      "Access-Control-Allow-Origin": "*",
      ...headers,
    },
    version,
    body: body ? JSON.stringify(body) : null,
    mode: 'cors',
    cache: 'default'
  };

  // Build URL
  const uri = resource;

  return fetch(uri, {
    ...init,
    headers: {
      ...init.headers,
    }
  })
    .then((response: Response) => {
      if (response.ok) {
        if (response.status === 204) {
          return {
            status: response.status
          };
        }

        if (response.status === 202) {
          return {
            status: response.status
          };
        }
        return response.json();
      }
      throw response;
    })
    .catch((response: Response): any =>
      response.json().then(data => {

        if (data.errors) {
          throw data.errors;
        }
        throw data;
      })
    );
};