import {
  GET_TAX_LIST_SAGA,
  GET_TAX_LIST,
  GET_TAX_AGENCIES,
  CREAT_TAX_AGENCY
} from 'constants/ActionTypes';

export const getTaxRegimeList = (data) => {
  return {
    type: GET_TAX_LIST_SAGA,
    payload: data,
  };
};

export const getTaxAgency = (data) => {
  return {
    type: GET_TAX_AGENCIES,
    payload: data,
  };
}

export const createTaxAgency = (data) => {
  return {
    type: CREAT_TAX_AGENCY,
    payload: data,
  };
}

export const updateTaxListInStore = (taxList) => {
  return {
    type: GET_TAX_LIST,
    payload: taxList,
  };
};