import { ADD_ALL_ACCOUNTS, ADD_ACCOUNT } from 'constants/ActionTypes';

/* eslint-disable no-case-declarations */
const initialAccountsMock = [
  // {
  //   id: 1,
  //   name: 'LF Scientific',
  //   accountNumber: '12345678',
  //   lastActiveAt: 'Fri August 23, 2019',
  //   accountStatus: 'pending',
  //   punchoutStatus: 'inactive',
  //   accountRequestedAt: 'Fri August 9, 2019',
  //   accountProvisionedAt: 'Fri August 16, 2019',
  //   accountTAT: '7 days',
  //   punchoutProvisionedAt: 'Fri August 23, 2019',
  //   punchoutTAT: '14 days',
  //   orderMethod: 'ePO',
  //   paymentMethod: 'Credit Card',
  //   entityType: 'Corporation',
  //   federalTaxId: '354354354354',
  //   monthlyPurchase: '$10,000',
  //   intendedUse: 'Lab Works',
  //   dbNumber: '654354354',
  //   networkId: '5354354354',
  //   nonTaxable: 'Yes',
  //   resellOrDistribute: 'Yes',
  //   billingContactName: 'Lab Fellow',
  //   billingContactNumber: '123-456-7890',
  //   billingAddress: '101 W Broadway St. San Diego, CA',
  //   shippingContactName: 'Lab Fellow',
  //   shippingContactNumber: '123-456-7890',
  //   shippingAddress: '101 W Broadway St. San Diego, CA',
  //   bankName: 'BioBank',
  //   bankAccountNumber: '1010101010101010',
  //   bankRepresentativeName: 'My Fav Banker',
  //   bankEmailAddress: 'myFav@biobank.com',
  //   bankAddress: '123 Bank St.',
  //   bankCity: 'Bank City',
  //   bankZipcode: '101010',
  //   bankState: 'Bank State',
  //   tradeReferences: [
  //     {
  //       company: 'my reference',
  //       accountNumber: '111111',
  //       firstName: 'Jo1',
  //       lastName: 'Ref1',
  //       emailAddress: 'joRef@myRef.com',
  //     },
  //     {
  //       company: 'another great one',
  //       accountNumber: '2222222',
  //       firstName: 'my',
  //       lastName: 'friend',
  //       emailAddress: 'thebest@myRef.com',
  //     },
  //     {
  //       company: 'wow chemicals',
  //       accountNumber: '33134524536',
  //       firstName: 'favorite',
  //       lastName: 'person',
  //       emailAddress: 'favperosn@myRef.com',
  //     },
  //   ],
  //   documents: [
  //     {
  //       name: 'first doc',
  //       type: 'tax info',
  //       description: 'this is the first doc with tax info in it',
  //       link: 'www.google.com',
  //     },
  //     {
  //       name: 'second doc',
  //       type: 'tax free info',
  //       description: 'this is the second doc and its a lot of fun',
  //       link: 'www.google.com',
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   name: 'GoPablo',
  //   accountNumber: '12345678',
  //   lastActiveAt: 'Fri August 23, 2019',
  //   accountStatus: 'active',
  //   punchoutStatus: 'pending',
  //   accountRequestedAt: 'Fri August 9, 2019',
  //   accountProvisionedAt: 'Fri August 16, 2019',
  //   accountTAT: '7 days',
  //   punchoutProvisionedAt: 'Fri August 23, 2019',
  //   punchoutTAT: '14 days',
  //   orderMethod: 'ePO',
  //   paymentMethod: 'Credit Card',
  //   accountAssignee: 'Awesome Account Person',
  //   punchoutAssignee: 'Power Punchout Person',
  //   entityType: 'Corporation',
  //   federalTaxId: '354354354354',
  //   monthlyPurchase: '$10,000',
  //   intendedUse: 'Lab Works',
  //   dbNumber: '654354354',
  //   networkId: '5354354354',
  //   nonTaxable: 'Yes',
  //   resellOrDistribute: 'Yes',
  //   billingContactName: 'Lab Fellow',
  //   billingContactNumber: '123-456-7890',
  //   billingAddress: '101 W Broadway St. San Diego, CA',
  //   shippingContactName: 'Lab Fellow',
  //   shippingContactNumber: '123-456-7890',
  //   shippingAddress: '101 W Broadway St. San Diego, CA',
  //   bankName: 'BioBank',
  //   bankAccountNumber: '1010101010101010',
  //   bankRepresentativeName: 'My Fav Banker',
  //   bankEmailAddress: 'myFav@biobank.com',
  //   bankAddress: '123 Bank St.',
  //   bankCity: 'Bank City',
  //   bankZipcode: '101010',
  //   bankState: 'Bank State',
  //   tradeReferences: [
  //     {
  //       company: 'my reference',
  //       accountNumber: '111111',
  //       firstName: 'Jo1',
  //       lastName: 'Ref1',
  //       emailAddress: 'joRef@myRef.com',
  //     },
  //     {
  //       company: 'another great one',
  //       accountNumber: '2222222',
  //       firstName: 'my',
  //       lastName: 'friend',
  //       emailAddress: 'thebest@myRef.com',
  //     },
  //     {
  //       company: 'wow chemicals',
  //       accountNumber: '33134524536',
  //       firstName: 'favorite',
  //       lastName: 'person',
  //       emailAddress: 'favperosn@myRef.com',
  //     },
  //   ],
  //   documents: [
  //     {
  //       name: 'first doc',
  //       type: 'tax info',
  //       description: 'this is the first doc with tax info in it',
  //       link: 'www.google.com',
  //     },
  //     {
  //       name: 'second doc',
  //       type: 'tax free info',
  //       description: 'this is the second doc and its a lot of fun',
  //       link: 'www.google.com',
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   name: 'Tofu Scientific',
  //   accountNumber: '12345678',
  //   lastActiveAt: 'Fri August 23, 2019',
  //   accountStatus: 'inactive',
  //   punchoutStatus: 'inactive',
  //   accountRequestedAt: 'Fri August 9, 2019',
  //   accountProvisionedAt: 'Fri August 16, 2019',
  //   accountTAT: '7 days',
  //   punchoutProvisionedAt: 'Fri August 23, 2019',
  //   punchoutTAT: '14 days',
  //   orderMethod: 'ePO',
  //   paymentMethod: 'Credit Card',
  //   accountAssignee: 'Awesome Account Person',
  //   punchoutAssignee: 'Power Punchout Person',
  //   entityType: 'Corporation',
  //   federalTaxId: '354354354354',
  //   monthlyPurchase: '$10,000',
  //   intendedUse: 'Lab Works',
  //   dbNumber: '654354354',
  //   networkId: '5354354354',
  //   nonTaxable: 'Yes',
  //   resellOrDistribute: 'Yes',
  //   billingContactName: 'Lab Fellow',
  //   billingContactNumber: '123-456-7890',
  //   billingAddress: '101 W Broadway St. San Diego, CA',
  //   shippingContactName: 'Lab Fellow',
  //   shippingContactNumber: '123-456-7890',
  //   shippingAddress: '101 W Broadway St. San Diego, CA',
  //   bankName: 'BioBank',
  //   bankAccountNumber: '1010101010101010',
  //   bankRepresentativeName: 'My Fav Banker',
  //   bankEmailAddress: 'myFav@biobank.com',
  //   bankAddress: '123 Bank St.',
  //   bankCity: 'Bank City',
  //   bankZipcode: '101010',
  //   bankState: 'Bank State',
  //   tradeReferences: [
  //     {
  //       company: 'my reference',
  //       accountNumber: '111111',
  //       firstName: 'Jo1',
  //       lastName: 'Ref1',
  //       emailAddress: 'joRef@myRef.com',
  //     },
  //     {
  //       company: 'another great one',
  //       accountNumber: '2222222',
  //       firstName: 'my',
  //       lastName: 'friend',
  //       emailAddress: 'thebest@myRef.com',
  //     },
  //     {
  //       company: 'wow chemicals',
  //       accountNumber: '33134524536',
  //       firstName: 'favorite',
  //       lastName: 'person',
  //       emailAddress: 'favperosn@myRef.com',
  //     },
  //   ],
  //   documents: [
  //     {
  //       name: 'first doc',
  //       type: 'tax info',
  //       description: 'this is the first doc with tax info in it',
  //       link: 'www.google.com',
  //     },
  //     {
  //       name: 'second doc',
  //       type: 'tax free info',
  //       description: 'this is the second doc and its a lot of fun',
  //       link: 'www.google.com',
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   name: 'David\'s Chemicals',
  //   accountNumber: '12345678',
  //   lastActiveAt: 'Fri August 23, 2019',
  //   accountStatus: 'active',
  //   punchoutStatus: 'active',
  //   accountRequestedAt: 'Fri August 9, 2019',
  //   accountProvisionedAt: 'Fri August 16, 2019',
  //   accountTAT: '7 days',
  //   punchoutProvisionedAt: 'Fri August 23, 2019',
  //   punchoutTAT: '14 days',
  //   orderMethod: 'ePO',
  //   paymentMethod: 'Credit Card',
  //   accountAssignee: 'Awesome Account Person',
  //   punchoutAssignee: 'Power Punchout Person',
  //   entityType: 'Corporation',
  //   federalTaxId: '354354354354',
  //   monthlyPurchase: '$10,000',
  //   intendedUse: 'Lab Works',
  //   dbNumber: '654354354',
  //   networkId: '5354354354',
  //   nonTaxable: 'Yes',
  //   resellOrDistribute: 'Yes',
  //   billingContactName: 'Lab Fellow',
  //   billingContactNumber: '123-456-7890',
  //   billingAddress: '101 W Broadway St. San Diego, CA',
  //   shippingContactName: 'Lab Fellow',
  //   shippingContactNumber: '123-456-7890',
  //   shippingAddress: '101 W Broadway St. San Diego, CA',
  //   bankName: 'BioBank',
  //   bankAccountNumber: '1010101010101010',
  //   bankRepresentativeName: 'My Fav Banker',
  //   bankEmailAddress: 'myFav@biobank.com',
  //   bankAddress: '123 Bank St.',
  //   bankCity: 'Bank City',
  //   bankZipcode: '101010',
  //   bankState: 'Bank State',
  //   tradeReferences: [
  //     {
  //       company: 'my reference',
  //       accountNumber: '111111',
  //       firstName: 'Jo1',
  //       lastName: 'Ref1',
  //       emailAddress: 'joRef@myRef.com',
  //     },
  //     {
  //       company: 'another great one',
  //       accountNumber: '2222222',
  //       firstName: 'my',
  //       lastName: 'friend',
  //       emailAddress: 'thebest@myRef.com',
  //     },
  //     {
  //       company: 'wow chemicals',
  //       accountNumber: '33134524536',
  //       firstName: 'favorite',
  //       lastName: 'person',
  //       emailAddress: 'favperosn@myRef.com',
  //     },
  //   ],
  //   documents: [
  //     {
  //       name: 'first doc',
  //       type: 'tax info',
  //       description: 'this is the first doc with tax info in it',
  //       link: 'www.google.com',
  //     },
  //     {
  //       name: 'second doc',
  //       type: 'tax free info',
  //       description: 'this is the second doc and its a lot of fun',
  //       link: 'www.google.com',
  //     },
  //   ],
  // },
  // {
  //   id: 5,
  //   name: 'Budd',
  //   accountNumber: '12345678',
  //   lastActiveAt: 'Fri August 23, 2019',
  //   accountStatus: 'pending',
  //   punchoutStatus: 'pending',
  //   accountRequestedAt: 'Fri August 9, 2019',
  //   accountProvisionedAt: 'Fri August 16, 2019',
  //   accountTAT: '7 days',
  //   punchoutProvisionedAt: 'Fri August 23, 2019',
  //   punchoutTAT: '14 days',
  //   orderMethod: 'ePO',
  //   paymentMethod: 'Credit Card',
  //   accountAssignee: 'Awesome Account Person',
  //   punchoutAssignee: 'Power Punchout Person',
  //   entityType: 'Corporation',
  //   federalTaxId: '354354354354',
  //   monthlyPurchase: '$10,000',
  //   intendedUse: 'Lab Works',
  //   dbNumber: '654354354',
  //   networkId: '5354354354',
  //   nonTaxable: 'Yes',
  //   resellOrDistribute: 'Yes',
  //   billingContactName: 'Lab Fellow',
  //   billingContactNumber: '123-456-7890',
  //   billingAddress: '101 W Broadway St. San Diego, CA',
  //   shippingContactName: 'Lab Fellow',
  //   shippingContactNumber: '123-456-7890',
  //   shippingAddress: '101 W Broadway St. San Diego, CA',
  //   bankName: 'BioBank',
  //   bankAccountNumber: '1010101010101010',
  //   bankRepresentativeName: 'My Fav Banker',
  //   bankEmailAddress: 'myFav@biobank.com',
  //   bankAddress: '123 Bank St.',
  //   bankCity: 'Bank City',
  //   bankZipcode: '101010',
  //   bankState: 'Bank State',
  //   tradeReferences: [
  //     {
  //       company: 'my reference',
  //       accountNumber: '111111',
  //       firstName: 'Jo1',
  //       lastName: 'Ref1',
  //       emailAddress: 'joRef@myRef.com',
  //     },
  //     {
  //       company: 'another great one',
  //       accountNumber: '2222222',
  //       firstName: 'my',
  //       lastName: 'friend',
  //       emailAddress: 'thebest@myRef.com',
  //     },
  //     {
  //       company: 'wow chemicals',
  //       accountNumber: '33134524536',
  //       firstName: 'favorite',
  //       lastName: 'person',
  //       emailAddress: 'favperosn@myRef.com',
  //     },
  //   ],
  //   documents: [
  //     {
  //       name: 'first doc',
  //       type: 'tax info',
  //       description: 'this is the first doc with tax info in it',
  //       link: 'www.google.com',
  //     },
  //     {
  //       name: 'second doc',
  //       type: 'tax free info',
  //       description: 'this is the second doc and its a lot of fun',
  //       link: 'www.google.com',
  //     },
  //   ],
  // },
];

const initialAccounts = {
  allAccounts: initialAccountsMock,
  accounts: [],
};

const accounts = (state = initialAccounts, action) => {
  switch (action.type) {
    case ADD_ALL_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload.accounts,
      };
    case ADD_ACCOUNT:
      const accountUuid = action.payload.uuid;
      const accounts = state.accounts.filter(account => account.uuid !== accountUuid);
      accounts.push(action.payload);
      return {
        ...state,
        accounts,
      };
    default:
      return state;
  }
};

export default accounts;
