import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  GET_INVOICE_LIST_SAGA,
  GET_INVOICE_SAGA,
  UPDATE_INVOICE_SAGA,
  GET_INVOICE_LIST, GET_PURCHASE_ORDER_FOR_INVOICE_SAGA,
  GET_PURCHASE_ORDER_FOR_INVOICE,
  GET_ORGANIZATION_LIST_SAGA,
  UPDATE_INVOICE_CHECKBOX_SAGE
} from 'constants/ActionTypes';
import {
  successMessage,
  errorMessage,
  updateAllInvoiceInStore,
  updateMoreInvoiceInStore,
  updateInvoiceInStore,
  updateOrganizationsInStore
} from 'actions';
import { LFAPI } from 'util/LFAPI';

function* getAllInvoice(action) {

  let { payload } = action;
  let queryString = (payload.is_credit_memo ? `&is_credit_memo=${payload.is_credit_memo}` : '&is_credit_memo=false') +
    (payload.no_approval_status ? `&no_approval_status=${payload.no_approval_status}` : '&no_approval_status=false') +
    (payload.offset ? `&offset=${payload.offset}` : '&offset=0') +
    (payload.limit ? `&limit=${payload.limit}` : '&limit=5') +
    (payload.sortby ? `&sortby=${payload.sortby}` : '') +
    (payload.sort ? `&sort=${payload.sort}` : '') +
    (payload.paymentStatus ? `&payment_status=${payload.paymentStatus}` : '') +
    (payload.syncedStatus ? `&synced_status=${payload.syncedStatus}` : '') +
    (payload.organizations ? `&organizations=${payload.organizations}` : '') +
    (payload.cmStatus ? `&credit_memo_status=${payload.cmStatus}` : '') +
    (payload.searchValue ? `&search=${payload.searchValue}` : '') +
    (payload.filterMonth ? `&month=${payload.filterMonth}` : '') +
    (payload.filterYear ? `&year=${payload.filterYear}` : '') +
    (payload.hasOwnProperty('externalInvoiceIdStatus') ? `&external_invoice_id_status=${payload.externalInvoiceIdStatus}` : '')

  try {
    const response = yield LFAPI.get(`/invoices/all?${queryString}`);
    let invoices = response && response.data && response.data.data && response.data;
    if (payload.loadMore) {
      yield put(updateMoreInvoiceInStore(invoices));
    } else {
      yield put(updateAllInvoiceInStore(invoices));
    }
  } catch (err) {
    yield put(updateAllInvoiceInStore([]));
    yield put(errorMessage('There was an error retrieving users. Please try again.', 'Error'));
  }
}

function* getInvoice(action) {
  let { payload } = action;
  let queryString =
    (payload.is_credit_memo ? `is_credit_memo=${payload.is_credit_memo}` : 'is_credit_memo=false') +
    (payload.uuid ? `&uuid=${payload.uuid}` : '');
  try {
    const response = yield LFAPI.get(`/invoices/invoice?${queryString}`);
    let invoice = response && response.data && response.data.data && response.data.data.invoice;
    let result = invoice.length ? invoice[0] : {};
    yield put(updateInvoiceInStore(result));
  } catch (err) {
    yield put(updateInvoiceInStore({}));
    yield put(errorMessage('There was an error retrieving users. Please try again.', 'Error'));
  }
}

// function* createInvoice(action) {
//   let { payload } = action;
//   try {
//     const response = yield LFAPI.post(`/invoices/create`, payload);
//     if (response && response.data && response.data.success) {
//       yield put(successMessage('Invoice created successfully', 'Success'));
//       yield put({ type: GET_INVOICE_LIST });
//     } else
//       yield put(
//         errorMessage('There was an error in creating the Invoice. Please try again.', 'Error'),
//       );
//   } catch (err) {
//     yield put(
//       errorMessage('There was an error in creating the Invoice. Please try again.', 'Error'),
//     );
//   }
// }

function* updateInvoice(action) {
  let { payload } = action
  try {
    const response = yield LFAPI.put(`/invoices/invoice`, payload);
    if (response && response.data && response.data.success) {
      yield put(successMessage('Invoice updated successfully', 'Success'));
    } else {
      yield put(errorMessage('Invoice Failed to Update', 'Error'));
    }
  } catch (err) {
    yield put(errorMessage('Invoice Failed to Update', 'Error'));
  }
}

function* getPurchaseOrderForInvoice(action) {
  let { payload } = action;
  let queryString = (payload.invoice_id ? `&invoice_id=${payload.invoice_id}` : '')
  try {
    const response = yield LFAPI.get(`/invoices/purchase_order?${queryString}`)
    if (response && response.data && response.data.success) {
      yield put({ type: GET_PURCHASE_ORDER_FOR_INVOICE, payload: response.data.data.purchaseOrderForInvoice })
    } else {
      yield put(
        errorMessage('There was an error in retrieving order for the Invoice. Please try again.', 'Error'),
      );
    }
  } catch (err) {
    yield put(
      errorMessage('There was an error in retrieving order for the Invoice. Please try again.', 'Error'),
    );
  }
}

function* updateCheckBoxInv(action) {
  let queryString = (action.payload ? `invoice_id=${action.payload.invoice}&checkbox=${action.payload.checkboxValue}` : '')

  try {
    const response = yield LFAPI.get(`/invoices/updateCheckbox?${queryString}`);
    if (response && response.data && response.data.success) {
      yield put(successMessage(response.data.message, 'Success'));
    } else {
      yield put(errorMessage('Quickbooks sync update fails', 'Error'));
    }
  } catch (err) {
    yield put(errorMessage('Quickbooks sync update fails', 'Error'));
  }
}

function* getAllOrg() {
  try {
    const response = yield LFAPI.get(`/accounts/accounts`);
    let listData = response && response.data && response.data.data && response.data.data.accounts;
    yield put(updateOrganizationsInStore(listData));
  } catch (err) {
    yield put(updateOrganizationsInStore([]));
    yield put(errorMessage('There was an error retrieving users. Please try again.', 'Error'));
  }
}


export function* getAllInvoiceSagaLink() {
  yield takeEvery(GET_INVOICE_LIST_SAGA, getAllInvoice);
}
export function* updateInvoiceCheckox() {
  yield takeEvery(UPDATE_INVOICE_CHECKBOX_SAGE, updateCheckBoxInv);
}

export function* getInvoiceSagaLink() {
  yield takeEvery(GET_INVOICE_SAGA, getInvoice);
}

export function* getAllOrgSagaLink() {
  yield takeEvery(GET_ORGANIZATION_LIST_SAGA, getAllOrg);
}

export function* watchCreateInvoiceSaga() {
  // console.log('')
  // yield takeEvery(CREATE_INVOICE_SAGA, createInvoice);
}

export function* watchGetPurchaseOrderForInvoiceSaga() {
  yield takeEvery(GET_PURCHASE_ORDER_FOR_INVOICE_SAGA, getPurchaseOrderForInvoice)
}

export function* watchUpdateInvoiceSaga() {
  yield takeEvery(UPDATE_INVOICE_SAGA, updateInvoice)
}

export default function* rootSaga() {
  yield all([fork(getAllInvoiceSagaLink), fork(updateInvoiceCheckox), fork(getInvoiceSagaLink), fork(watchCreateInvoiceSaga), fork(watchGetPurchaseOrderForInvoiceSaga), fork(watchUpdateInvoiceSaga), fork(getAllOrgSagaLink)]);
}
