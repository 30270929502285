import React from 'react';
import { connect } from 'react-redux';
import TextField from 'components/TextField';
import { Checkbox, Button, CircularProgress } from '@material-ui/core';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { hideMessage, showAuthLoader, setNewUserPassword } from 'actions/Auth';
import queryString from 'query-string';
import { checkPasswords } from 'util/PasswordParser';

class SignUp extends React.Component {
  constructor() {
    super();
    this.state = {
      email: null,
      t: null,
      password: '',
      confirmPassword: '',
      agree: false,
    };
    this.submitNewUserPassword = this.submitNewUserPassword.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line no-restricted-globals
    const urlParams = queryString.parse(location.search);
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    } else if (!urlParams.t || !urlParams.email) {
      this.props.history.replace('/signin');
    } else {
      let { t, email } = urlParams;
      email = email.replace(' ', '+');
      this.setState({ t, email });
    }
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 3000);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  submitNewUserPassword() {
    if (!this.state.agree) {
      NotificationManager.error(<IntlMessages id="users.agreeToTerms" />);
    } else {
      const { password, confirmPassword, t, email } = this.state;
      const { valid, error } = checkPasswords(password, confirmPassword);
      if (!valid) {
        NotificationManager.error(<IntlMessages id={error} />);
      } else {
        this.props.showAuthLoader();
        this.props.setNewUserPassword({
          email,
          password,
          confirmPassword,
          confirmationToken: t,
        });
      }
    }
  }

  render() {
    const { email, password, confirmPassword } = this.state;
    const { showMessage, loader, alertMessage } = this.props;
    return (
      <div className="app-login-container d-flex flex-column justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="d-flex text-center align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="LabFellows">
            <img
              style={{ maxWidth: '40%', marginBottom: '2rem' }}
              src={require('assets/images/LF-logo-name-horizontal-black.svg')}
              alt="LabFellows"
              title="LabFellows"
            />
          </Link>
        </div>

        <div className="app-login-main-content app-signup-main-content">
          <div className="app-login-content">
            <div className="app-login-header">
              <h1>Welcome to LabFellows!</h1>
              <IntlMessages id="signUp.setPassword" />
            </div>

            <div className="app-login-form">
              <form method="post" action="/">
                <TextField
                  type="password"
                  onChange={event => this.setState({ password: event.target.value })}
                  label={<IntlMessages id="appModule.password" />}
                  defaultValue={password}
                />

                <TextField
                  type="password"
                  onChange={event => this.setState({ confirmPassword: event.target.value })}
                  label={<IntlMessages id="appModule.reenterPassword" />}
                  defaultValue={confirmPassword}
                />

                <div className="mt-2 mb-2 d-flex align-items-center justify-content-between text-16">
                  <span>
                    <Checkbox
                      style={{ width: 50, height: 50 }}
                      checked={this.state.agree}
                      onChange={() => {
                        this.setState({ agree: !this.state.agree });
                      }}
                      value="agree"
                      color="primary"
                      inputProps={{
                        'aria-label': 'I agree to the terms and conditions',
                      }}
                    />
                  </span>
                  <span className="text-16">
                    <IntlMessages id="signUp.iAgreeTo" />
                    {'  '}
                    <a
                      className="lf-link mb-2 mt-2"
                      href="https://www.labfellows.com/terms-of-service"
                      title="linkToTermsOfService"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IntlMessages id="signIn.termsAndConditions" />
                    </a>
                  </span>
                </div>

                <div className="mt-2 mb-4 d-flex align-items-center justify-content-between text-16">
                  <IntlMessages id="signUp.passwordRequirement" />
                </div>

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button
                    className="btn-full-width"
                    variant="contained"
                    onClick={() => {
                      this.submitNewUserPassword();
                    }}
                    color="primary"
                  >
                    <IntlMessages id="appModule.submit" />
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(
  mapStateToProps,
  {
    setNewUserPassword,
    hideMessage,
    showAuthLoader,
  },
)(SignUp);
