import { IntlProvider } from 'react-intl';
import { select } from 'redux-saga/effects';
import AppLocale from '../lngProvider';

export const settings = state => state.settings;

export default function* translate(id) {
  try {
    const storeSettings = yield select(settings);
    let language = 'en';
    if (storeSettings && storeSettings.locale) {
      language = yield storeSettings.locale.locale || 'en';
    }
    // eslint-disable-next-line no-unused-vars
    const { locale, messages } = AppLocale[language];
    const { intl } = new IntlProvider({ locale: language, messages }, {}).getChildContext();
    return intl.formatMessage({ id });
  } catch (err) {
    throw new Error(err);
  }
}
