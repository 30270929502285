import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from 'components/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import { unlockAccountEmailRequest } from 'actions/Auth';
import { NotificationManager } from 'react-notifications';

const initialState = {
  email: '',
  hiddenEmail: '',
  unlockSubmitted: false,
};
class RequestUnlock extends React.Component {
  constructor() {
    super();
    this.state = initialState;
    this.requestUnlockEmail = this.requestUnlockEmail.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {}

  requestUnlockEmail() {
    const { email } = this.state;
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
      // censor the middle characters of the email address with *
      let hiddenEmail = email.split('@');
      const censorRegex = /(?<!^).(?!$)/g;
      hiddenEmail[0] = hiddenEmail[0].replace(censorRegex, '*');
      hiddenEmail = hiddenEmail.join('@');
      this.setState({ hiddenEmail, unlockSubmitted: true });
      this.props.unlockAccountEmailRequest(email);
    } else {
      NotificationManager.error(<IntlMessages id="signIn.invalidEmail" />);
    }
  }

  render() {
    return (
      <div className="app-login-container d-flex flex-column justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="d-flex text-center align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="LabFellows">
            <img
              style={{ maxWidth: '40%', marginBottom: '2rem' }}
              src={require('assets/images/LF-logo-name-horizontal-black.svg')}
              alt="LabFellows"
              title="LabFellows"
            />
          </Link>
        </div>

        <div className="mt-4 app-login-main-content">
          {/* submit request unlock email screen */}
          <div className={`app-login-content ${this.state.unlockSubmitted ? ' d-none' : ''}`}>
            <div className="mt-4 app-login-header mb-4">
              <h1 className="mt-4 forgotPassword extra-bold text-28">
                <IntlMessages id="accounts.unlockAccount" />
              </h1>
              <span className="text-16">
                <IntlMessages id="accounts.unlockAccountDescription" />
              </span>
            </div>

            <div className="app-login-form">
              <form
                onSubmit={e => {
                  e.preventDefault();
                  this.requestUnlockEmail();
                }}
              >
                <fieldset>
                  <TextField
                    type="email"
                    label={<IntlMessages id="appModule.email" />}
                    onChange={event => this.setState({ email: event.target.value })}
                  />
                  <div className="mt-4">
                    <Button
                      className="btn-full-width"
                      onClick={() => {
                        this.requestUnlockEmail();
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="accounts.unlockAccount" />
                    </Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
          {/* end request unlock email screen */}
          {/* success screen */}
          <div className={`app-login-content ${this.state.unlockSubmitted ? ' ' : ' d-none'}`}>
            <div className="d-flex flex-column align-items-center">
              <div>
                <i className="fas fa-check-circle color-green text-50 mb-4" />
              </div>
              <div className="app-login-header mb-4">
                <h1 className="forgotPassword extra-bold text-28">
                  <IntlMessages id="accounts.unlockAccountEmailSent" />
                </h1>
                <span className="text-16">
                  <IntlMessages id="forgotPassword.resetEmailSentDescription" />
                  {this.state.hiddenEmail}
                  <IntlMessages id="accounts.unlockAccountEmailSentDescription" />
                </span>
              </div>
            </div>
            <Button
              className="btn-full-width"
              onClick={() => {
                this.setState({ ...initialState });
                this.props.history.push('/signin');
              }}
              variant="contained"
              color="primary"
            >
              <IntlMessages id="forgotPassword.done" />
            </Button>
          </div>
          {/* end success screen */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(
  mapStateToProps,
  {
    unlockAccountEmailRequest,
  },
)(RequestUnlock);
