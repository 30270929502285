import { GET_CHART_OF_ACCOUNT_LIST, MAKE_LOADER_ACTIVE } from 'constants/ActionTypes';

const initialChartOfAccounts = {
    chartOfAccountList: [],
    coaNameMapValue: {},
};

const chartOfAccounts = (state = initialChartOfAccounts, action) => {
    switch (action.type) {
        case GET_CHART_OF_ACCOUNT_LIST:
            let COAList = action.payload;
            let coaNameMapValue = {}
            COAList.sort((a, b) => {
                let value1 = a.name.toLowerCase(),
                    value2 = b.name.toLowerCase();

                if (value1 < value2) {
                    return -1;
                }
                if (value1 > value2) {
                    return 1;
                }
                return 0;
            });

            COAList.map(n => coaNameMapValue[n.uuid] = n.name)
            return {
                ...state,
                chartOfAccountList: COAList,
                coaNameMapValue: coaNameMapValue,
                loader: false
            };
        case MAKE_LOADER_ACTIVE:
            return {
                ...state,
                loader: action.payload
            };
        default:
            return state;
    }

};

export default chartOfAccounts;