import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  GET_TAX_LIST_SAGA
} from 'constants/ActionTypes';
import {
  successMessage,
  errorMessage,
  updateTaxListInStore,
} from 'actions';
import { LFAPI } from 'util/LFAPI';


function* getTaxList(action) {
  let { payload } = action;

  let queryString = '';
  Object.keys(payload).map(key => queryString = queryString + key + '=' + payload[key] + '&');


  try {
    const response = yield LFAPI.get(`/taxregimes/all?${queryString}`);
    let taxList = response && response.data && response.data.data && response.data

    yield put(updateTaxListInStore(taxList));
  } catch (err) {
    yield put(updateTaxListInStore([]));
    yield put(errorMessage('There was an error retrieving tax regimes. Please try again.', 'Error'));
  }
}


export function* getTaxListSagaLink() {
  yield takeEvery(GET_TAX_LIST_SAGA, getTaxList);
}


export default function* rootSaga() {
  yield all([
    fork(getTaxListSagaLink),
  ]);
}