import { GET_PAYMENT_PROFILE, UPDATE_PAYMENT_PROFILE, GET_PAYMENT_HISTORY } from 'constants/ActionTypes';

const initialPaymentState = {
  paymentProfile: {},
  paymentHistroy: []
}

const payments = (state = initialPaymentState, action) => {
  switch (action.type) {
    case GET_PAYMENT_PROFILE:
      return {
        ...state,
        paymentProfile: action.payload
      }
    case UPDATE_PAYMENT_PROFILE:
      return {
        ...state,
        ...action.payload.updates
      }
    case GET_PAYMENT_HISTORY:
      console.log("actionactionactionactionaction=>", action)
      return {
        ...state,
        paymentHistroy: action.payload
      }
    default:
      return state;
  }
}

export default payments;