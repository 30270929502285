/* eslint-disable import/no-named-default */

import * as React from 'react';
import { withStyles } from 'material-ui/styles';
import PropTypes from 'prop-types';
import {
  default as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'material-ui/Dialog';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Slide from 'material-ui/transitions/Slide';
import type { MuiTheme } from 'config/theme';
import type { FullDialog } from 'store/dialog';
import Typography from 'components/Typography';
import classnames from 'classnames';

const styles = (theme: MuiTheme) => ({
  paper: {
    padding: '1rem 1rem 0 1rem',
    borderRadius: '8px 8px 8px 8px',
    borderTop: '4px solid #d6e1f2',
  },
  noPaperScroll: {
    overflowY: 'hidden',
    padding: 0,
    minWidth: 916,
    maxWidth: 916,
    height: 790
  },
  titleRoot: {
    display: 'flex',
    alignItems: 'center',
    height: '2rem',
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    overflow: 'hidden',
  },
  titleText: {
    ...theme.typography.button,
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    color: '#404352',
    paddingBottom: '1rem',
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 385,
  },
  close: {
    transform: 'rotate(45deg)',
  },
  content: {
    padding: theme.spacing.unit * 2,
    borderTop: `1px solid #D6E1F2`,
    borderBottom: `1px solid #D6E1F2`,
  },
  reportContent: {
    overflow: "visible",
    padding: theme.spacing.unit * 2,
    paddingTop: 0,
    paddingBottom: 0,
    borderTop: `1px solid #D6E1F2`,
    borderBottom: `1px solid #D6E1F2`,
  },
  noTitle: {
    padding: theme.spacing.unit * 2,
    borderBottom: `1px solid #D6E1F2`,
  },
  noBodyPadding: {
    padding: 0
  },
  largeMargin: {
    padding: '34px 24px 34px 24px'
  },
  noPaddingContent: {
    padding: theme.spacing.unit * 2,
    borderBottom: `1px solid #D6E1F2`,
    paddingBottom: 0,
    marginBottom: '-1rem'
  },
  normalPadding: {
    // borderBottom: `1px solid #D6E1F2`,
  },
  noScroll: {
    overflowY: 'hidden',
    overflowX: 'hidden',
    paddingTop: '0 !important'
  },
  verifyPopupRoot: {
    overflowY: 'hidden',
    overflowX: 'hidden',
    paddingTop: '0 !important',
    borderBottom:'none',
    padding: '0 15px 15px 15px'
  },
  placeItemTable: {
    maxWidth: '576px',
    borderRadius: '4px',
    height: '792px'
  },
  noActions: {
    padding: theme.spacing.unit * 2,
    borderTop: `1px solid #D6E1F2`,
  },
  wide: {
    minWidth: 760,
    width: 1200,
    height: 623,
    padding: '1rem 1rem 0 1rem',
    borderRadius: '8px 8px 8px 8px',
    borderTop: '4px solid #d6e1f2',
  },
  wideNoHeight: {
    minWidth: 760,
    width: 1200,
    padding: '1rem 1rem 0 1rem',
    borderRadius: '8px 8px 8px 8px',
    borderTop: '4px solid #d6e1f2',
  },
  noPadding: {
    padding: 0,
    minWidth: 900,
    maxWidth: 900,
    height: 600,
    borderRadius: '8px 8px 8px 8px',
    borderTop: '4px solid #d6e1f2',
  },
  profile: {
    minWidth: 400,
    padding: 0,
    borderRadius: '8px 8px 8px 8px',
    borderTop: '4px solid #d6e1f2',
  },
  smallPadding: {
    width: 500,
    padding: '1rem 1rem 0 1rem',
    borderRadius: '8px 8px 8px 8px',
    borderTop: '4px solid #d6e1f2',
  },
  modifyOrder: {
    minWidth: 1176,
    maxWidth: 1176,
    minHeight: 462,
    padding: '1rem 1rem 0 1rem',
    borderRadius: '8px 8px 8px 8px',
    borderTop: '4px solid #d6e1f2',
  },
  approvalFlow: {
    minWidth: 1200,
    maxWidth: 1200,
    minHeight: 650,
    padding: '1rem 1rem 0 1rem',
    borderRadius: '8px 8px 8px 8px',
    borderTop: '4px solid #d6e1f2',
  },
  setPassword: {
    minWidth: 576,
    borderRadius: '8px 8px 8px 8px',
    borderTop: '4px solid #d6e1f2',
  },
  reportEdit: {
    // minWidth: 600,
    height: 335,
    padding: '1rem 1rem 0 1rem',
    borderRadius: '8px 8px 8px 8px',
    borderTop: '4px solid #d6e1f2',
  },
  reportCreate: {
    // minWidth: 600,
    height: 426,
    padding: '1rem 1rem 0 1rem',
    borderRadius: '8px 8px 8px 8px',
    borderTop: '4px solid #d6e1f2',
  },
  tableSize: {
    minWidth: 576,
    height: 556,
    borderRadius: '4px',
    borderTop: '4px solid #d6e1f2'
  },
  tableMod: {
    minWidth: 576,
    height: 556,
    borderRadius: '8px 8px 8px 8px',
    borderTop: '4px solid #d6e1f2',
  },
  addItem: {
    minWidth: 576,
    height: 790,
    borderRadius: '8px 8px 8px 8px',
    borderTop: '4px solid #d6e1f2'
  },
  inventoryCard: {
    maxWidth: 576,
    height: 790,
    padding: 0,
    borderRadius: '8px 8px 8px 8px',
    borderTop: '4px solid #d6e1f2'
  },
  inventoryExpand: {
    overflowY: 'hidden',
    minWidth: 916,
    maxWidth: 916,
  },
  submitCount: {
    height: 250,
    borderRadius: '4px'
  },
  inventoryEdit: {
    width: '100%',
    height: 260,
    padding: '1rem 1rem 0 1rem',
    borderRadius: '8px 8px 8px 8px',
    borderTop: '4px solid #d6e1f2',
  },
  contentText: {
    color: theme.palette.primary.dark,
    fontSize: '0.875rem',
    '@global sub': {
      fontSize: '0.75rem',
      lineHeight: 1,
    },
  },
  actions: {
    marginLeft: 0,
    marginRight: 0,
    paddingTop: '0.50rem',
    paddingBottom: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
  },
  deleteButton: {
    height: "17px",
    color: "#64a6f4",
    border: "2px solid #64a6f4",
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#5089CC',
      color: '#ffffff !important'
    }
  },
  cancelButton: {
    color: "#64a6f4",
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#5089CC',
      color: '#ffffff !important'
    },
  },
  warning: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    color: '#fff',
    background: '#F7981C',
    textAlign: 'center',
    padding: 5,
    fontSize: 12,
    fontWeight: 500
  },
  flatButton: {
    color: '#ffff',
    backgroundColor: '#64A6F4',
    borderRadius: '4px',
    '&:hover': {
      opacity: '1',
      backgroundColor: '#157FF2',
    },
    '&:disabled': {
      backgroundColor: '#cadcf1'
    },
    '& > span': {
      color: '#fff'
    },
    padding: '0 10px'
  },
  flatButtonWhite: {
    backgroundColor: '#ffffff',
    border: '1px solid #64A6F4',
    borderRadius: '4px',
    margin: '0 1rem 0 0',
    '&:hover': {
      '& > span': {
        color: '#ffffff'
      },
      border: '1px solid #157FF2',
      backgroundColor: '#157FF2',
    },
    '& > span': {
      color: '#64A6F4'
    },
    '&:disabled': {
      opacity: '0.5',
      '& > span': {
        color: '#64A6F4'
      }
    },
    padding: '0 10px'
  },
  noBackDrop: {
    backgroundColor: 'transparent'
  },
  visible: {
    overflow: 'visible'
  },
  tabletSmallHeight: {
    maxHeight: '70vh'
  },
  phoneSmallHeight: {
    maxwidth: '66vw'
  }
});

class ComponentDialog extends React.Component {
  static propTypes = {
    show: PropTypes.bool,
    disableBackdrop: PropTypes.bool,
    color: PropTypes.string,
    title: PropTypes.string,
    customTitle: PropTypes.string,
    // body: PropTypes.Object,
    noActions: PropTypes.bool,
    wide: PropTypes.bool,
    cancelText: PropTypes.string,
    deleteText: PropTypes.string,
    acceptText: PropTypes.string,
    handleClose: PropTypes.func,
    handleAccept: PropTypes.func,
    handleCancel: PropTypes.func,
    handleDelete: PropTypes.func,
    classes: PropTypes.object,
    enable2FA: PropTypes.func,
    disableCloseIcon: PropTypes.bool,
    disableAcceptText: PropTypes.bool
  };

  static defaultProps = {
    auditPopup: false,
    tableSize: false,
    tableMod: false,
    placeItemTable: false,
    addItem: false,
    inventoryCard: false,
    inventoryExpand: false,
    submitCount: false,
    show: false,
    disableBackdrop: false,
    color: '',
    title: '',
    customTitle: false,
    // body: {},
    cancelText: '',
    deleteText: '',
    acceptText: '',
    noActions: false,
    wide: false,
    tabletSmallHeight: false,
    phoneSmallHeight: false,
    handleClose: () => { },
    handleAccept: () => { },
    handleCancel: () => { },
    handleDelete: () => { },
    classes: {},
    //2FA - Enable / Disable
    enable2FA: () => { },
    disableCloseIcon: false,
    disableAcceptText: false,
  };

  transition = props => <Slide direction="up" {...props} />;

  render() {
    const { show, noBackDrop, disableBackdrop, color, title, body, cancelText, deleteText, acceptText, tableSize, tableMod, addItem, inventoryCard, inventoryExpand,
      handleClose, handleAccept, handleCancel, handleDelete, classes, noActions, wide, customTitle, approvalFlow, modifyOrder,
      profile, maxWidth, warning, normalPadding, reportContent, noPadding, wideNoHeight, reportMenuHeight, noScroll, verifyPopupRoot, placeItemTable, setPassword,
      smallPadding, noPaperScroll, noBodyPadding, overlayCloseIcon, auditPopup, tabletSmallHeight, enable2FA, disableCloseIcon, phoneSmallHeight, disableAcceptText
    } = this.props;

    return (
      <MuiDialog
        maxWidth={maxWidth}
        open={show}
        disableBackdropClick={disableBackdrop}
        onClose={handleClose}
        onExited={enable2FA}
        transition={this.transition}
        BackdropProps={{
          classes: {
            root: classnames(noBackDrop && classes.noBackDrop)
          }
        }
        }
        classes={{ paper: classnames(classes.visible, smallPadding ? classes.smallPadding : noPadding ? classes.noPadding : modifyOrder ? classes.modifyOrder : (reportMenuHeight == "large") ? classes.reportCreate : (reportMenuHeight == "medium") ? classes.reportEdit : (reportMenuHeight == "small") ? classes.inventoryEdit : wide ? classes.wide : wideNoHeight ? classes.wideNoHeight : profile ? classes.profile : approvalFlow ? classes.approvalFlow : setPassword ? classes.setPassword : tableSize ? classes.tableSize : tableMod ? classes.tableMod : auditPopup ? classes.auditPopup : placeItemTable ? classes.placeItemTable : addItem ? classes.addItem : noPaperScroll ? classes.noPaperScroll : classes.paper, inventoryCard && classes.inventoryCard, inventoryExpand && classes.inventoryExpand, tabletSmallHeight && classes.tabletSmallHeight, phoneSmallHeight && classes.phoneSmallHeight ) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {
          overlayCloseIcon ?
            <div style={{ position: 'absolute', display: 'flex', justifyContent: 'flex-end', top: 0, width: '95%' }}>
              <div onClick={handleClose} style={{ cursor: 'pointer', padding: '1rem' }}>
                <Icon>close</Icon>
              </div>
            </div>
            :

            disableCloseIcon ?
            <span></span>
            :
            <div style={{ position: 'absolute', display: 'flex', justifyContent: 'flex-end', right: -5, top: -10, width: '95%', zIndex: 9999 }}>
              <div onClick={handleClose} style={{ cursor: 'pointer', fontSize: 15, backgroundColor: '#ffffff', height: 10, width: 10 }}>
                <Icon style={{ position: 'absolute', top: -7, right: -7 }}>cancel</Icon>
              </div>
            </div>
        }
        {
          title &&
          (
            <DialogTitle
              id="alert-dialog-title"
              disableTypography
              classes={{ root: classes.titleRoot }}
            >
              <span className={classes.titleText}>
                {title}
              </span>
            </DialogTitle>
          )
        }
        {/* for custom titles; allows for the addition of html/jsx into the title header */}
        {
          customTitle &&
          (
            <div style={{ display: 'flex', borderBottom: '1px solid #BCCDE4' }}>
              <span style={{ width: '100%' }}>
                {customTitle}
              </span>
            </div>
          )
        }
        {
          body &&
          <DialogContent className={classnames(reportContent ? classes.reportContent : title ? classes.content : normalPadding ? classes.normalPadding : setPassword ? classes.largeMargin : noBodyPadding ? classes.noBodyPadding : verifyPopupRoot ? classes.verifyPopupRoot : classes.noTitle, noScroll && classes.noScroll)}>
            {
              warning
              &&
              <Typography className={classes.warning}>{warning}</Typography>
            }

            {/* <Typography>{body}</Typography> */}
            {body}
          </DialogContent>
        }
        {
          !noActions &&
          (
            <DialogActions className={classes.actions}>
              {
                cancelText &&
                (
                  <Button
                    onClick={handleCancel}
                    color={color}
                    data-dialog="cancel"
                    className={classes.flatButtonWhite}
                  >
                    {cancelText}
                  </Button>
                )
              }
              {
                deleteText &&
                (
                  <Button
                    onClick={handleDelete}
                    color={"white"}
                    data-dialog="delete"
                    className={classes.deleteButton}
                  >
                    {deleteText}
                  </Button>
                )
              }
              {
                acceptText &&
                (
                  <Button
                    onClick={handleAccept}
                    disabled={disableAcceptText}
                    variant="flat"
                    color="secondary"
                    className={classes.flatButton}
                    data-dialog="accept"
                    style={{ color: "white", textTransform: 'capitalize' }}
                    autoFocus
                  >
                    {acceptText}
                  </Button>
                )
              }
            </DialogActions>
          )
        }
      </MuiDialog>
    )
  }
}

export default withStyles(styles, { withTheme: true })(ComponentDialog);
