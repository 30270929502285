/* eslint-disable no-restricted-globals */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from 'components/TextField';
import Button from '@material-ui/core/Button';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { hideMessage, showAuthLoader, userSignIn } from 'actions/Auth';
import queryString from 'query-string';

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      // email: 'demo@example.com',
      // password: 'demo#123'
      email: '',
      password: '',
      showError: false,
    };
  }

  componentDidMount() {
    const urlParams = queryString.parse(location.search);
    if (urlParams.invalidToken) {
      this.setState({ invalidToken: true });
    } else if (urlParams.invalidUnlock) {
      this.props.history.replace('/signin');
      NotificationManager.error(<IntlMessages id="accounts.invalidUnlockLink" />);
    } else if (urlParams.rejectedUnlock) {
      this.props.history.replace('/signin');
      NotificationManager.error(<IntlMessages id="accounts.invalidUnlockLink" />, 'Error', 20000);
    }
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    const { email, password } = this.state;
    const { showMessage, loader, alertMessage } = this.props;
    return (
      <div className="app-login-container d-flex flex-column justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="d-flex text-center align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="LabFellows">
            <img
              style={{ maxWidth: '40%', marginBottom: '2rem' }}
              src={require('assets/images/LF-logo-name-horizontal-black.svg')}
              alt="LabFellows"
              title="LabFellows"
            />
          </Link>
        </div>

        <div className="app-login-main-content">
          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>
                <IntlMessages id="signIn.welcome" />
              </h1>
              <span className="text-16">
                <IntlMessages id="signIn.setUserAndPw" />
              </span>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    type="email"
                    label={<IntlMessages id="appModule.email" />}
                    onChange={event => this.setState({ email: event.target.value })}
                    defaultValue={email}
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    onChange={event => this.setState({ password: event.target.value })}
                    defaultValue={password}
                  />
                  <span className="mb-3 text-large">
                    <Link
                      className="lf-link float-right text-16"
                      to="/forgotpassword"
                      title="linkToTermsOfService"
                    >
                      <IntlMessages id="signIn.forgotPassword" />
                    </Link>
                  </span>
                  <div className="mb-3 flex-column d-flex align-items-center justify-content-between float-left">
                    <span className="mt-3 mb-3 text-16">
                      <IntlMessages id="signIn.byLoggingIn" />
                      {'  '}
                      <a
                        className="lf-link mb-2 mt-2"
                        href="https://www.labfellows.com/terms-of-service"
                        title="linkToTermsOfService"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <IntlMessages id="signIn.termsAndConditions" />
                      </a>
                    </span>

                    <Button
                      className="btn-full-width"
                      onClick={() => {
                        this.props.showAuthLoader();
                        this.props.userSignIn({ email, password });
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="appModule.signIn" />
                    </Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {showMessage && NotificationManager.error(alertMessage, 'Error', 20000)}
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(
  mapStateToProps,
  {
    userSignIn,
    hideMessage,
    showAuthLoader,
  },
)(SignIn);
