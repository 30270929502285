import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import users from './Users';
import notifications from './Notifications';
import suppliers from './Suppliers';
import accounts from './Accounts';
import catalogs from './Catalogs';
import orders from './Orders';
import invoices from './Invoices';
import qbo from './Qbo';
import companies from './Companies';
import payments from './Payments';
import taxRegime from './TaxRegime';
import syncErrorLogs from './SyncErrorLogs';
import ChartOfAccount from './ChartOfAccounts'
export default function* rootSaga(getState) {
  yield all([authSagas(), users(), notifications(), suppliers(), accounts(), catalogs(), orders(), invoices(), qbo(), companies(), payments(), taxRegime(), syncErrorLogs(), ChartOfAccount()]);
}
